import React, { useEffect, useState } from "react";
import LineChart from "../components/Charts/LineChart";
import ColumnChart from "../components/Charts/ColumnChart";
import AreaChart from "../components/Charts/AreaChart";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Store from "../store/Store";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import MainError from "../components/Notifications/MainError";
import { changeNot } from "../store/urlSlice";

import usePrivate from "../store/useAxiosPrivate2";
const url = Store.getState().url.url;
function Home() {
  const { sendRequest } = usePrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [db, setdb] = useState(null);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const BackUpType = useSelector(state => state.auth.backUpType);
  const menus = useSelector(state => state.auth.menu);
  const menu = useSelector(state => state.url.menu);
  const status = useSelector(state => state.url.status);
  const notification = useSelector(state => state.url.notification);
  const [routes, setRoutes] = useState([]);
  const [cardload, setCardload] = useState(false);
  const currency = useSelector(state => state.auth.currency);

  const fetchData = async () => {
    console.log("hhmget");
    try {
      const res = await sendRequest(
        "GET",
        `GetData/Home/${selectedCompany}/${BackUpType}`,
        null
      );
      setdb(res);
      dispatch(changeNot({ type: res.isSuccess, message: res.message }));
      setCardload(false);
    } catch (err) {
      console.error(err);
      dispatch(changeNot({ type: err.isSuccess, message: err.message }));
      console.log(err);
    }
  };
  useEffect(() => {
    console.log("hhm");
    setdb(null);
    setCardload(true);
    if (status === 5) {
      const rolekeysToFilterArray = menus.map(item => item.rulekey);
      const filtermenu = menu.filter(item =>
        rolekeysToFilterArray.includes(item.rolekey)
      );
      setRoutes(filtermenu);
    } else {
      setRoutes(menu);
    }

    fetchData();
  }, [selectedCompany, menus, BackUpType]);

  function redirect(role) {
    let matchingItem = menu.find(x => x.rolekey === role);
    if (matchingItem) {
      navigate(matchingItem.to);
    } else {
      console.log("There is not the page you are looking for");
    }
  }
  return db != null ? (
    db.isSuccess ? (
      <div className="container">
        <div className="items">
          {routes.map(x =>
            x.rolekey === "Cash" && db.result.cash.length > 0 ? (
              <div
                // className={!cardload ? "item" : "item shimmer"}
                className="item"
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`cashTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                  </span>
                </div>
                <LineChart
                  data={db.result.cash.map(d => {
                    return {
                      x: d.x,
                      y: d[`y${currency.text}`].toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    };
                  })}
                  // data={db.result.cash}
                  toolbar={false}
                  labely={true}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Bank" && db.result.bank.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`bankTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                  </span>
                </div>
                <ColumnChart
                  data={db.result.bank}
                  toolbar={false}
                  color={"#53ba67"}
                  labely={true}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Receivable" &&
              db.result.receivable.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[
                      `receivableTotal${currency.text}`
                    ].toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <ColumnChart
                  data={db.result.receivable}
                  color={"#2f90ce"}
                  toolbar={false}
                  labely={true}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Payable" && db.result.payable.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`payableTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
                <ColumnChart
                  color={"#ed1952"}
                  data={db.result.payable}
                  toolbar={false}
                  labely={true}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Expenditure" &&
              db.result.expenditure.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[
                      `expenditureTotal${currency.text}`
                    ].toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <AreaChart
                  datas={db.result.expenditure.map(d => {
                    return {
                      x: d.x,
                      y: d[`y${currency.text}`].toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    };
                  })}
                  // datas={db.result.expenditure}
                  color={"#ee3137"}
                  toolbar={false}
                  resheight={120}
                  labely={true}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Income" && db.result.income.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`incomeTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
                <AreaChart
                  datas={db.result.income.map(d => {
                    return {
                      x: d.x,
                      y: d[`y${currency.text}`].toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    };
                  })}
                  color={"#16998b"}
                  toolbar={false}
                  resheight={120}
                  labely={true}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Inventory" && db.result.inventory.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`inventoryTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
                <AreaChart
                  datas={db.result.inventory.map(d => {
                    return {
                      x: d.x,
                      y: d[`y${currency.text}`].toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    };
                  })}
                  color={"#799F0C"}
                  toolbar={false}
                  resheight={120}
                  labely={true}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Production" &&
              db.result.production.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    {" "}
                    <i className={currency.icon}></i>
                    {db.result[`productionTotal${currency.text}`]}
                  </span>
                </div>
                <AreaChart
                  color={"#2775bb"}
                  toolbar={false}
                  resheight={120}
                  labely={true}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                  datas={db.result.production.map(d => {
                    return {
                      x: d.x,
                      y: d[`y${currency.text}`].toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    };
                  })}
                />
              </div>
            ) : x.rolekey === "Purchase" && db.result.purchase.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`purchaseTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
                <ColumnChart
                  data={db.result.purchase}
                  toolbar={false}
                  yaxis={false}
                  xaxis={false}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Sale" && db.result.sale.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`saleTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
                <ColumnChart
                  data={db.result.sale}
                  color={"#f69134"}
                  toolbar={false}
                  labely={true}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Tax" && db.result.tax.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`taxTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>{" "}
                <ColumnChart
                  data={db.result.tax}
                  toolbar={false}
                  color={"#665aa7"}
                  labely={true}
                  resheight={120}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                />
              </div>
            ) : x.rolekey === "Expense" && db.result.expense.length > 0 ? (
              <div
                className="item"
                // className={!cardload ? "item" : "item shimmer"}
                key={x.rolekey}
                namakey={x.rolekey}
                onClick={() => redirect(x.rolekey)}
              >
                <div className="title">
                  <h2>{t(x.text)}</h2>
                  <span>
                    <i className={currency.icon}></i>
                    {db.result[`expenseTotal${currency.text}`].toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
                <AreaChart
                  color={"#ed1952"}
                  toolbar={false}
                  resheight={120}
                  labely={true}
                  icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                  datas={db.result.expense.map(d => {
                    return {
                      x: d.x,
                      y: d[`y${currency.text}`].toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    };
                  })}
                />
              </div>
            ) : null
          )}
        </div>
      </div>
    ) : (
      <div>
        <h2 className="section-head" style={{ marginLeft: "20px" }}>
          {t("Məlumatyoxdur")}
        </h2>
        {notification ? <MainError /> : null}
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#0d2e4e"}
        color={"#0d2e4e"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  );
}

export default Home;
