import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "react-js-loader";
import Store from "../../store/Store";
import axiosprivate from "../../store/useAxiosPrivate2";
const url = Store.getState().url.url;

function Users() {
  const { sendRequest } = axiosprivate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [curRowId, setCurRowId] = useState(null);
  const [groups, setGroups] = useState(null);
  const navigate = useNavigate();
  const { groupid } = useParams();
  const token = useSelector(state => state.auth.token);
  const [user, setUser] = useState({
    username: "",
    userfirstname: "",
    userlastname: "",
    useremail: "",
    userphone: "",
    usercompanygroupuserid: groupid || 0,
  });
  const [validError, setValidError] = useState({
    id: null,
    username: null,
    useremail: null,
    userphone: null,
    userfirstname: null,
    userlastname: null,
    usercompanygroupuserid: null,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest(
          "GET",
          `Companies/GetEmployees/${groupid || ""}`,
          null
        );
        console.log(res);
        setData(res.data);
        setGroups(res.groups);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const handleChange = e => {
    let { name, value } = e.target;
    setValidError(prevErrors => ({
      ...prevErrors,
      id: null,
      [name]: null, // Replace with your actual validation error message
    }));
    if (name.endsWith("date")) {
      value = value.replace(/\D/g, "");
      if (value.length <= 2) {
      } else if (value.length <= 4) {
        value = `${value.slice(0, 2)}.${value.slice(2)}`;
      } else {
        value = `${value.slice(0, 2)}.${value.slice(2, 4)}.${value.slice(
          4,
          8
        )}`;
      }
    }
    setUser({ ...user, [name]: value });
  };

  const SendData = async (data, method, url) => {
    try {
      // Example of using sendRequest for GET request
      const postResult = await sendRequest(method, url, data);
      console.log(postResult);
      if (postResult?.status === 200) {
        if (method === "POST") {
          console.log("post", method);
          setData(prevData =>
            Array.isArray(prevData)
              ? prevData.concat(postResult.data)
              : [postResult.data]
          );
          console.log("post2", method);
        } else if (method === "PUT") {
          console.log("put", method);
          setData(data =>
            data.map(d => (d.userid === curRowId ? { ...d, ...user } : d))
          );
        }
        handleClose();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400) {
        setValidError({
          id: null,
          username: error.response.data.errors.Username
            ? error.response.data.errors.Username[0]
            : null,
          useremail: error.response.data.errors.Useremail
            ? error.response.data.errors.Useremail[0]
            : null,
          userphone: error.response.data.errors.Userphone
            ? error.response.data.errors.Userphone[0]
            : null,
          userfirstname: error.response.data.errors.Userfirstname
            ? error.response.data.errors.Userfirstname[0]
            : null,
          userlastname: error.response.data.errors.Userlastname
            ? error.response.data.errors.Userlastname[0]
            : null,
          usercompanygroupuserid: error.response.data.errors
            .Usercompanygroupuserid
            ? error.response.data.errors.Usercompanygroupuserid[0]
            : null,
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (curRowId === null) {
      SendData(user, "POST", "Companies/AddEmployee");
    } else {
      const us = {
        ...user,
        id: curRowId,
      };
      SendData(us, "PUT", "Companies/UpdateEmployee");
    }
  };
  const handleDelete = async () => {
    try {
      // Example of using sendRequest for GET request
      const postResult = await sendRequest(
        "DELETE",
        `Companies/DeleteEmployee/${curRowId}`
      );
      console.log(postResult);
      if (postResult.status === 200) {
        setData(data.filter(d => d.userid !== curRowId));
        setCurRowId(null);
        setModalDelete(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    setValidError({
      id: null,
      username: null,
      useremail: null,
      userphone: null,
      userfirstname: null,
      userlastname: null,
      usercompanygroupuserid: null,
    });
    setUser({
      username: "",
      userfirstname: "",
      userlastname: "",
      useremail: "",
      userphone: "",
      usercompanygroupuserid: groupid || 0,
    });
    setModal(false);
    setCurRowId(null);
    setModalDelete(false);
  };

  const rightClick = (e, id) => {
    e.preventDefault();
    const contextMenu = document.querySelector(".context-menu");
    const topPosition =
      e.pageY + contextMenu.clientHeight > window.innerHeight
        ? e.pageY - contextMenu.clientHeight
        : e.pageY;
    contextMenu.style.display = "block";
    contextMenu.style.left = e.pageX + "px";
    contextMenu.style.top = topPosition + "px";
    setCurRowId(id);
  };

  return data !== null ? (
    <>
      <div className="container">
        <h2 className="section-head">{t("Company_employees")}</h2>
        <table className="table-box">
          <thead>
            <tr>
              <th>№</th>
              <th>{t("name")}</th>
              <th>{t("surname")}</th>
              <th>{t("İstifadəçiadı")}</th>
              <th>{t("email")}</th>
              <th>{t("phone")}</th>
              <th>{t("Şirkətqrupu")}</th>
              <th>
                <button
                  className="btn"
                  style={{
                    cursor: "pointer",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    backgroundColor: "#2db686",
                    border: "none",
                    color: "white",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() => {
                    setModal(true);
                    setCurRowId(null);
                  }}
                >
                  +  {t("Yeniİşçi")}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => {
              return (
                <tr
                  key={d.userid}
                  onContextMenu={event => rightClick(event, d.userid)}
                >
                  <td>{i + 1}</td>
                  <td>{d.userfirstname}</td>
                  <td>{d.userlastname}</td>
                  <td>{d.username}</td>
                  <td>{d.useremail}</td>
                  <td>{d.userphone}</td>
                  <td>{d.companygroup}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {modal ? (
        <div className="modal-right">
          <div className="modal-body">
            {validError.id ? (
              <div style={{ color: "red", textAlign: "center" }}>
                {validError.id}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label>{t("İstifadəçininadı")}</label>
              <input
                type="text"
                style={
                  validError.userfirstname ? { border: "solid 1px red" } : {}
                }
                placeholder={t("name") + "..."}
                name="userfirstname"
                value={user.userfirstname}
                onChange={handleChange}
              />
              {validError.userfirstname ? (
                <span style={{ color: "red" }}>{validError.userfirstname}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("İstifadəçininsoyadı")}</label>
              <input
                type="text"
                style={
                  validError.userlastname ? { border: "solid 1px red" } : {}
                }
                placeholder={t("surname") + "..."}
                name="userlastname"
                value={user.userlastname}
                onChange={handleChange}
              />
              {validError.userlastname ? (
                <span style={{ color: "red" }}>{validError.userlastname}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("İstifadəçiadı")}</label>
              <input
                type="text"
                style={validError.username ? { border: "solid 1px red" } : {}}
                placeholder={t("İstifadəçiadı") + "..."}
                name="username"
                value={user.username}
                onChange={handleChange}
              />
              {validError.username ? (
                <span style={{ color: "red" }}>{validError.username}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Emailünvanı")}</label>
              <input
                type="text"
                style={validError.useremail ? { border: "solid 1px red" } : {}}
                placeholder={t("email") + "..."}
                name="useremail"
                value={user.useremail}
                onChange={handleChange}
              />
              {validError.useremail ? (
                <span style={{ color: "red" }}>{validError.useremail}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Əlaqənömrəsi")}</label>
              <input
                type="text"
                style={validError.userphone ? { border: "solid 1px red" } : {}}
                placeholder={t("phone") + "..."}
                name="userphone"
                value={user.userphone}
                onChange={handleChange}
              />
              {validError.userphone ? (
                <span style={{ color: "red" }}>{validError.userphone}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Daxilolduğuqrup")}</label>
              <select
                name="usercompanygroupuserid"
                style={
                  validError.usercompanygroupuserid
                    ? { border: "solid 1px red" }
                    : {}
                }
                onChange={handleChange}
                defaultValue={user.usercompanygroupuserid}
              >
                <option value="0">{t("seçin")}...</option>
                {groups.map(x => {
                  return (
                    <option key={x.userid} value={x.userid}>
                      {x.userfirstname}
                    </option>
                  );
                })}
              </select>
              {validError.usercompanygroupuserid ? (
                <span style={{ color: "red" }}>
                  {validError.usercompanygroupuserid}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button style={{ cursor: "pointer" }} onClick={handleClose}>
              {t("Ləğvet")}
            </button>
            <button style={{ cursor: "pointer" }} onClick={handleSubmit}>
              {t("Təsdiqet")}
            </button>
          </div>
        </div>
      ) : null}
      {modalDelete ? (
        <div className="modal-delete">
          <div className="modal-body">
            <p>{t("Seçilmişsətrisilməkistədiyinizəəminsinizmi?")}</p>
            <p>
              {" "}
              {data.find(d => d.userid === curRowId).userfirstname} -{" "}
              {data.find(d => d.userid === curRowId).userlastname}
            </p>
          </div>
          <div className="modal-footer">
            <button style={{ cursor: "pointer" }} onClick={handleClose}>
              {t("Ləğvet")}
            </button>
            <button style={{ cursor: "pointer" }} onClick={handleDelete}>
              {t("Təsdiqet")}
            </button>
          </div>
        </div>
      ) : null}
      <div className="context-menu">
        <ul>
          <li onClick={() => navigate("/rules/userid/" + curRowId)}>
            <i className="fa-solid fa-shield-halved"></i> {t("Səlahiyyətlər")}
          </li>
          <li
            onClick={() => {
              setModal(true);
              setCurRowId(null);
            }}
          >
            <i className="fa-solid fa-plus"></i> {t("Yeniİşçi")}
          </li>
          <li
            onClick={() => {
              setModal(true);
              const d = data.find(x => x.userid === curRowId);
              setUser({
                username: d.username,
                userfirstname: d.userfirstname,
                userlastname: d.userlastname,
                useremail: d.useremail,
                userphone: d.userphone,
                usercompanygroupuserid: d.usercompanygroupuserid,
              });
            }}
          >
            <i className="fa-regular fa-pen-to-square"></i> {t("Dəyiş")}
          </li>
          <li
            onClick={() => {
              setModalDelete(true);
            }}
          >
            <i className="fa-regular fa-trash-can"></i> {t("Sil")}
          </li>
        </ul>
      </div>
    </>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#2db686"}
        color={"#2db686"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  );
}

export default Users;
