import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MainAdmin from "./MainAdmin";
import MainGroup from "./MainGroup";
import MainEmployee from "./MainEmployee";
import Login from "./views/Login.js";
import axios from "axios";
import { changeMenu } from "./store/authSlice.js";
import { changeStatus } from "./store/urlSlice.js";
import Loader from "react-js-loader";
import Loginroute from "./Loginroute.js";
import { logout } from "./store/authSlice.js";
function App() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const url = useSelector(state => state.url.url);
  const statusId = useSelector(state => state.url.status);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated && token) {
      axios
        .get(`${url}GetData/GetRoute/${selectedCompany}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          dispatch(changeMenu({ menu: res.data.result.menu }));
          dispatch(changeStatus({ status: res.data.result.status }));
        })
        .catch(err => {
          console.log(err);
          dispatch(logout());
          dispatch(changeStatus({ status: null }));
          setLoading(false);
        });
    }
    if (statusId !== null || !isAuthenticated) {
      setLoading(false);
    } else if (selectedCompany === 0) {
      setLoading(false);
    }
  }, [isAuthenticated, statusId]);

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#0d2e4e"}
        color={"#0d2e4e"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  ) : isAuthenticated === true && statusId !== null ? (
    statusId === 1 ? (
      <MainAdmin />
    ) : statusId === 2 ? (
      <MainGroup />
    ) : statusId === 5 ? (
      <MainEmployee />
    ) : null
  ) : (
    <Loginroute />
  );
}

export default App;
