import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function Flags() {
  const [langStatus, setLangStatus] = useState("az");
  const [langName, setLangname] = useState("Az");
  const { i18n } = useTranslation();
  const changeLanguage = useCallback(
    async lang => {
      await i18n.changeLanguage(lang);
    },
    [i18n]
  );
  useEffect(() => {
    changeLanguage(langStatus);
  }, [langStatus, changeLanguage]);
  const setLang = e => {
    setLangStatus(e.target.dataset.value);
    setLangname(e.target.dataset.name);
    // setModal(false);
  };
  return (
    <div>
      <ul className="Flags">
        <li
          key="az"
          onClick={e => setLang(e)}
          value="az"
          name="Az"
          data-value="az"
          data-name="Az"
          style={{ display: "flex", flexDirection: "column", margin: "0" }}
        >
          <span className="flagIcon">
            <svg
              onClick={e => setLang(e)}
              value="az"
              name="Az"
              data-value="az"
              data-name="Az"
              width="20"
              height="20"
              viewBox="0 0 513 342"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_301_1600)">
                <path
                  onClick={e => setLang(e)}
                  value="az"
                  name="Az"
                  data-value="az"
                  data-name="Az"
                  d="M0 -0.00585938H513V341.994H0V-0.00585938Z"
                  fill="#EF3340"
                />
                <path
                  onClick={e => setLang(e)}
                  value="az"
                  name="Az"
                  data-value="az"
                  data-name="Az"
                  d="M0 -0.00585938H513V113.994H0V-0.00585938Z"
                  fill="#00B5E2"
                />
                <path
                  onClick={e => setLang(e)}
                  value="az"
                  name="Az"
                  data-value="az"
                  data-name="Az"
                  d="M0 227.994H513V341.994H0V227.994Z"
                  fill="#509E2F"
                />
                <path
                  onClick={e => setLang(e)}
                  value="az"
                  name="Az"
                  data-value="az"
                  data-name="Az"
                  d="M265.6 212.594C242.6 212.594 224 193.994 224 170.994C224 147.994 242.6 129.394 265.6 129.394C272.8 129.394 279.5 131.194 285.4 134.394C276.2 125.394 263.5 119.794 249.6 119.794C221.3 119.794 198.4 142.694 198.4 170.994C198.4 199.294 221.3 222.194 249.6 222.194C263.5 222.194 276.2 216.594 285.4 207.594C279.5 210.794 272.8 212.594 265.6 212.594Z"
                  fill="white"
                />
                <path
                  onClick={e => setLang(e)}
                  value="az"
                  name="Az"
                  data-value="az"
                  data-name="Az"
                  d="M297.6 142.194L303.1 157.694L318 150.594L310.9 165.494L326.4 170.994L310.9 176.494L318 191.394L303.1 184.294L297.6 199.794L292.1 184.294L277.2 191.394L284.3 176.494L268.8 170.994L284.3 165.494L277.2 150.594L292.1 157.694L297.6 142.194Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_301_1600">
                  <rect
                    width="513"
                    height="342"
                    fill="white"
                    transform="translate(0 -0.00585938)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span
            key="az"
            onClick={e => setLang(e)}
            value="az"
            name="Az"
            data-value="az"
            data-name="Az"
          >
            {" "}
            az
          </span>
        </li>
        <li
          key="en"
          onClick={e => setLang(e)}
          value="en"
          name="En"
          data-value="en"
          data-name="En"
          style={{ display: "flex", flexDirection: "column", margin: "0" }}
        >
          {" "}
          <span className="flagIcon">
            <svg
              onClick={e => setLang(e)}
              value="en"
              name="En"
              data-value="en"
              data-name="En"
              width="20"
              height="20"
              viewBox="0 0 513 343"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_301_1200)">
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M0 0.926514H513V342.93H0V0.926514Z"
                  fill="white"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M288.562 0.923584H224.438V139.86H0V203.985H224.438V342.922H288.562V203.985H513V139.86H288.562V0.923584Z"
                  fill="#D80027"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M394.554 231.401L513 297.205V231.401H394.554ZM312.261 231.401L513 342.922V311.387L369.026 231.401H312.261ZM459.53 342.922L312.261 261.099V342.922H459.53Z"
                  fill="#0052B4"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M312.261 231.401L513 342.922V311.387L369.026 231.401H312.261Z"
                  fill="white"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M312.261 231.401L513 342.922V311.387L369.026 231.401H312.261Z"
                  fill="#D80027"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M90.5174 231.399L0 281.687V231.399H90.5174ZM200.739 245.58V342.921H25.5408L200.739 245.58Z"
                  fill="#0052B4"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M143.974 231.401L0 311.387V342.922L200.739 231.401H143.974Z"
                  fill="#D80027"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M118.446 112.445L0 46.6407V112.445H118.446ZM200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739ZM53.4702 0.923584L200.739 82.7471V0.923584H53.4702Z"
                  fill="#0052B4"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739Z"
                  fill="white"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739Z"
                  fill="#D80027"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M422.483 112.447L513 62.1589V112.447H422.483ZM312.261 98.2653V0.924561H487.459L312.261 98.2653Z"
                  fill="#0052B4"
                />
                <path
                  onClick={e => setLang(e)}
                  value="en"
                  name="En"
                  data-value="en"
                  data-name="En"
                  d="M369.026 112.445L513 32.4591V0.923584L312.261 112.445H369.026Z"
                  fill="#D80027"
                />
              </g>
              <defs>
                <clipPath id="clip0_301_1200">
                  <rect
                    width="513"
                    height="342"
                    fill="white"
                    transform="translate(0 0.926514)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span
            onClick={e => setLang(e)}
            value="en"
            name="En"
            data-value="en"
            data-name="En"
          >
            {" "}
            en
          </span>
        </li>
        <li
          key="ru"
          onClick={e => setLang(e)}
          value="ru"
          name="Ru"
          data-value="ru"
          data-name="Ru"
          style={{ display: "flex", flexDirection: "column", margin: "0" }}
        >
          {" "}
          <span className="flagIcon">
            <svg
              onClick={e => setLang(e)}
              value="ru"
              name="Ru"
              data-value="ru"
              data-name="Ru"
              width="20"
              height="20"
              viewBox="0 0 513 343"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_301_429)">
                <path
                  onClick={e => setLang(e)}
                  value="ru"
                  name="Ru"
                  data-value="ru"
                  data-name="Ru"
                  d="M0 0.986328V342.985H513V0.986328H0Z"
                  fill="white"
                />
                <path
                  onClick={e => setLang(e)}
                  value="ru"
                  name="Ru"
                  data-value="ru"
                  data-name="Ru"
                  d="M0 0.989258H513V342.993H0V0.989258Z"
                  fill="#0052B4"
                />
                <path
                  onClick={e => setLang(e)}
                  value="ru"
                  name="Ru"
                  data-value="ru"
                  data-name="Ru"
                  d="M0 0.989258H513V114.986H0V0.989258Z"
                  fill="white"
                />
                <path
                  onClick={e => setLang(e)}
                  value="ru"
                  name="Ru"
                  data-value="ru"
                  data-name="Ru"
                  d="M0 228.984H513V342.982H0V228.984Z"
                  fill="#D80027"
                />
              </g>
              <defs>
                <clipPath id="clip0_301_429">
                  <rect
                    width="513"
                    height="342"
                    fill="white"
                    transform="translate(0 0.989258)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span
            onClick={e => setLang(e)}
            value="ru"
            name="Ru"
            data-value="ru"
            data-name="Ru"
          >
            {" "}
            ru
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Flags;
