import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LineChart from "../../components/Charts/LineChart";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import PieChart from "../../components/Charts/PieChart";
import { changeNot } from "../../store/urlSlice";
import MainError from "../../components/Notifications/MainError";
import ColumnChart from "../../components/Charts/ColumnChart";
import usePrivate from "../../store/useAxiosPrivate2";
function Production() {
  const { sendRequest } = usePrivate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notification = useSelector(state => state.url.notification);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const BackUpType = useSelector(state => state.auth.backUpType);
  const [db, setdb] = useState(null);
  const [type, setType] = useState("chart");
  const currency = useSelector(state => state.auth.currency);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest(
          "GET",
          `GetData/Production/${selectedCompany}/${BackUpType}`,
          null
        );

        dispatch(changeNot({ type: res.isSuccess, message: res.message }));
        setdb(res);
      } catch (err) {
        console.error(err);
        dispatch(changeNot({ type: err.isSuccess, message: err.message }));
        console.log(err);
      }
    };

    fetchData();
  }, [BackUpType, selectedCompany]);

  return db !== null ? (
    db.isSuccess ? (
      db.result.group.length > 0 ? (
        <div className="cash-detail">
          <div className="cash-head">
            <h3>
              {t("Dovr")}:
              {db.isSuccess
                ? db.result[`total${currency.text}`].toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0}{" "}
              <i className={currency.icon}></i>
            </h3>
            <ul className="show-type">
              <li
                className={type !== "table" ? "active" : "hide"}
                onClick={() => setType("table")}
              >
                <span>{t("Cədvəlgörünüşü")}</span>
                <i className="fa-solid fa-bars"></i>
              </li>
              <li
                className={type !== "chart" ? "active" : "hide"}
                onClick={() => setType("chart")}
              >
                <span>{t("Qrafikgörünüşü")}</span>
                <i className="fa-solid fa-chart-line"></i>
              </li>
            </ul>
          </div>
          <div className="pie">
            {type === "chart" ? (
              <PieChart
                s={db.result.group.map(d => d[`sum${currency.text}`])}
                l={db.result.group.map(d => d.productionproductgroup)}
              />
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>{t("Group")}</th>
                    <th>{t("Məbləğ")}</th>
                  </tr>
                </thead>
                <tbody>
                  {db.result.group.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{d.productionproductgroup}</td>
                        <td className="textal">
                          {d[`sum${currency.text}`].toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          <i className={currency.icon}></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <div className="area">
            {type === "chart" ? (
              <ColumnChart
                data={Object.values(
                  db.result.today.reduce((acc, item) => {
                    const date = item.productiondate;
                    const amount = item[
                      `productionamount${currency.text}`
                    ].toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });

                    acc[date] = acc[date] || { x: date, y: 0 };
                    acc[date].y += amount;

                    return acc;
                  }, {})
                )}
                color={"#ee9ca7"}
                height="100%"
                toolbar={true}
                labelx={true}
                icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                labely={true}
              />
            ) : (
              <table className="table">
                <tbody>
                  {[...db.result.history].reverse().map((d, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr className="date-row">
                          <td colSpan={6}>
                            {d.date} -{" "}
                            {d.sum.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            AZN
                          </td>
                        </tr>
                        {d.rows.map((row, j) => {
                          return (
                            <tr key={j}>
                              <td>{row.productionproductgroup}</td>
                              <td>{row.productionproductname}</td>
                              <td className="textal">
                                {row.productionquantity}
                              </td>
                              <td>{row.productionunit}</td>
                              <td className="textal">
                                {row[
                                  `productionamount${currency.text}`
                                ].toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                {" "}
                                <i className={currency.icon}></i>
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <div style={{ padding: "45px 13px" }}>
          <h2 className="section-head" style={{ marginLeft: "20px" }}>
            {t("Məlumatyoxdur")}
          </h2>
        </div>
      )
    ) : (
      <div style={{ padding: "45px 13px" }}>
        <h2 className="section-head" style={{ marginLeft: "20px" }}>
          {t("Məlumatyoxdur")}
        </h2>
        {notification ? <MainError /> : null}
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#0d2e4e"}
        color={"#0d2e4e"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  );
}

export default Production;
