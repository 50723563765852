import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./views/Home";
import NoPage from "./views/NoPage";
import Cash from "./views/details/Cash";
import Bank from "./views/details/Bank";
import Login from "./views/Login";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Expenditure from "./views/details/Expenditure";
import Income from "./views/details/Income";
import Receviable from "./views/details/Receviable";
import Payeble from "./views/details/Payeble.js";
import Inventory from "./views/details/Inventory.js";
import Production from "./views/details/Production.js";
import Purchase from "./views/details/Purchase.js";
import Sale from "./views/details/Sale.js";
import Access from "./views/Access.js";
import Expense from "./views/details/Expense.js";
import Tax from "./views/details/Tax.js";

function MainAdmin() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="detail/cash" element={<Cash />} />
          <Route path="detail/Bank" element={<Bank />} />
          <Route path="detail/Receivable" element={<Receviable />} />
          <Route path="detail/Payable" element={<Payeble />} />
          <Route path="detail/Expenditure" element={<Expenditure />} />
          <Route path="detail/Income" element={<Income />} />
          <Route path="detail/Inventory" element={<Inventory />} />
          <Route path="detail/Production" element={<Production />} />
          <Route path="detail/Purchase" element={<Purchase />} />
          <Route path="detail/Sale" element={<Sale />} />
          <Route path="detail/Expense" element={<Expense />} />
          <Route path="detail/Tax" element={<Tax />} />
          <Route path="/access" element={<Access />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default MainAdmin;
