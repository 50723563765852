import React, { useEffect } from "react";
import { changeNot } from "../../store/urlSlice";
import { useDispatch, useSelector } from "react-redux";
function MainError({ text, type }) {
  const notification = useSelector(state => state.url.notification);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(notification);
    setTimeout(() => {
      dispatch(changeNot({ type: false, message: null }));
    }, 10000);
  }, [notification]);
  return (
    <div className="bottom-right-error">Error: {notification.message}</div>
  );
}

export default MainError;
