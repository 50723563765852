import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "react-js-loader";
import { useTranslation } from "react-i18next";
import axiosprivate from "../../store/useAxiosPrivate2";

function Companies() {
  const { sendRequest } = axiosprivate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [modalDelete, setModalDelete] = useState(false);
  const [curRowId, setCurRowId] = useState(null);
  const [groups, setGroups] = useState(null);
  const { groupid } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({
    companiesDataCompanyId: "",
    companiesDataVoen: "",
    companiesDataPhoneAz: "",
    companiesDataPhoneNar: " ",
    companiesDataPhoneBak: "",
    companiesDataEmail: "",
    companiesDataBankAcoount: "",
    companiesDataAdress: "",
    companiesDataFullName: "",
    companiesDataPosition: "",
  });
  const [company, setCompany] = useState({
    companyname: "",
    companyvoen: "",
    companyregdate: "",
    companyexpiredate: "",
    companymaxusercount: 0,
    companygroupuserid: groupid || 0,
  });
  const [validError, setValidError] = useState({
    id: null,
    companyname: null,
    companyvoen: null,
    companyregdate: null,
    companyexpiredate: null,
    companymaxusercount: null,
    companygroupuserid: null,
  });
  const handleChange = e => {
    let { name, value } = e.target;
    setValidError(prevErrors => ({
      ...prevErrors,
      id: null,
      [name]: null, // Replace with your actual validation error message
    }));

    if (name.endsWith("date")) {
      value = value.replace(/\D/g, "");
      if (value.length <= 2) {
      } else if (value.length <= 4) {
        value = `${value.slice(0, 2)}.${value.slice(2)}`;
      } else {
        value = `${value.slice(0, 2)}.${value.slice(2, 4)}.${value.slice(
          4,
          8
        )}`;
      }
    }
    setCompany({ ...company, [name]: value });
  };

  const SendData = async (data, method, url) => {
    try {
      // Example of using sendRequest for GET request
      const postResult = await sendRequest(method, url, data);
      console.log(postResult);
      if (postResult?.status === 200) {
        if (method === "POST") {
          console.log("post", method);
          setData(prevData =>
            Array.isArray(prevData)
              ? prevData.concat(postResult.data)
              : [postResult.data]
          );
          console.log("post2", method);
        } else if (method === "PUT") {
          console.log("put", method);
          setData(data =>
            data.map(d =>
              d.companyid === curRowId ? { ...d, ...postResult.data } : d
            )
          );
        }
        handleClose();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400) {
        setValidError({
          id: null,
          companyname: error.response.data.errors.Companyname
            ? error.response.data.errors.Companyname[0]
            : null,
          companyvoen: error.response.data.errors.Companyvoen
            ? error.response.data.errors.Companyvoen[0]
            : null,
          companyregdate: error.response.data.errors.Companyregdate
            ? error.response.data.errors.Companyregdate[0]
            : null,
          companyexpiredate: error.response.data.errors.Companyexpiredate
            ? error.response.data.errors.Companyexpiredate[0]
            : null,
          companygroupuserid: error.response.data.errors.companygroupuserid
            ? error.response.data.errors.companygroupuserid[0]
            : null,
          companymaxusercount: error.response.data.errors.Companymaxusercount
            ? error.response.data.errors.Companymaxusercount[0]
            : null,
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (curRowId === null) {
      const dataad = {
        ...company,
        companyregdate: moment(company.companyregdate, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        ),
        companyexpiredate: moment(
          company.companyexpiredate,
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD"),
      };
      SendData(dataad, "POST", "Companies/AddCompany");
    } else {
      const dataup = {
        ...company,
        id: curRowId,
        companyregdate: moment(company.companyregdate, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        ),
        companyexpiredate: moment(
          company.companyexpiredate,
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD"),
      };
      SendData(dataup, "PUT", "Companies/UpdateCompany");
    }
  };

  const handleDelete = async () => {
    try {
      // Example of using sendRequest for GET request
      const postResult = await sendRequest(
        "DELETE",
        `Companies/DeleteCompany/${curRowId}`
      );
      console.log(postResult);
      if (postResult.status === 200) {
        setData(data.filter(d => d.companyid !== curRowId));
        setCurRowId(null);
        setModalDelete(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    setCompany({
      companyname: "",
      companyvoen: "",
      companyregdate: "",
      companyexpiredate: "",
      companymaxusercount: 0,
      companygroupuserid: groupid || 0,
    });

    setValidError({
      id: null,
      companyname: null,
      companyvoen: null,
      companyregdate: null,
      companyexpiredate: null,
      companymaxusercount: null,
      companygroupuserid: null,
    });
    setModal(false);
    setCurRowId(null);
    setModalDelete(false);
  };

  const handleClose3 = () => {
    setModal3(false);
    setCompanyData({
      companiesDataCompanyId: "",
      companiesDataVoen: "",
      companiesDataPhoneAz: "",
      companiesDataPhoneNar: " ",
      companiesDataPhoneBak: "",
      companiesDataEmail: "",
      companiesDataBankAcoount: "",
      companiesDataAdress: "",
      companiesDataFullName: "",
      companiesDataPosition: "",
    });
  };
  const GetData = async id => {
    try {
      const res = await sendRequest("GET", `Companies/CompanyData/${id}`, null);
      console.log(res);
      if (res) {
        console.log(res);
        console.log(companyData);
        setCompanyData(res);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleOpenData = id => {
    console.log(id);
    GetData(id);
    setModal3(true);
    setCompanyData({ ...companyData, ["companiesDataCompanyId"]: id });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest(
          "GET",
          `Companies/GetCompanies/${groupid || ""}`,
          null
        );
        console.log(res);
        setData(res.data);
        setGroups(res.groups);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [groupid]);
  const rightClick = (e, id) => {
    e.preventDefault();
    const contextMenu = document.querySelector(".context-menu");
    const topPosition =
      e.pageY + contextMenu.clientHeight > window.innerHeight
        ? e.pageY - contextMenu.clientHeight
        : e.pageY;
    contextMenu.style.display = "block";
    contextMenu.style.left = e.pageX + "px";
    contextMenu.style.top = topPosition + "px";
    setCurRowId(id);
  };

  const handleChangeData = e => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log(name);
    console.log(value);
    setCompanyData({ ...companyData, [name]: value });
  };
  const SendHaqqinda = async () => {
    console.log(companyData);
    try {
      const postResult = await sendRequest(
        "POST",
        `Companies/CompanyData/${companyData.companiesDataCompanyId}`,
        companyData
      );
      if (postResult) {
        setModal3(false);
        console.log(postResult);
      } else {
        console.log(postResult);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return data !== null ? (
    <>
      <div className="container">
        <h2 className="section-head">{t("Şirkətlər")} </h2>
        <table className="table-box">
          <thead>
            <tr>
              <th>№</th>
              <th>{t("Şirkətinadı")}</th>
              <th>{t("VÖEN")}</th>
              <th>{t("Qeydiyyattarixi")}</th>
              <th>{t("Bitmətarixi")}</th>
              <th>{t("İstifadəçisayı")}</th>
              <th>{t("Qrup")}</th>
              <th>
                <button
                  className="btn"
                  style={{
                    cursor: "pointer",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    backgroundColor: "#2db686",
                    border: "none",
                    color: "white",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() => {
                    setModal(true);
                    setCurRowId(null);
                  }}
                >
                  + {t("YeniŞirkət")}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              .sort(function (a, b) {
                return a.companyid - b.companyid;
              })
              .map((d, i) => {
                return (
                  <tr
                    key={d.companyid}
                    onContextMenu={event => rightClick(event, d.companyid)}
                  >
                    <td>{i + 1}</td>
                    <td>{d.companyname}</td>
                    <td>{d.companyvoen}</td>
                    <td>{d.companyregdate}</td>
                    <td>{d.companyexpiredate}</td>
                    <td>
                      {d.companyusercount}/{d.companymaxusercount}
                    </td>
                    <td>{d.companygroupname}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {modal ? (
        <div className="modal-right">
          <div className="modal-body">
            {validError.id ? (
              <div style={{ color: "red", textAlign: "center" }}>
                {validError.id}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label>{t("Şirkətinadı")}</label>
              <input
                type="text"
                style={
                  validError.companyname ? { border: "solid 1px red" } : {}
                }
                placeholder={t("name") + "..."}
                name="companyname"
                value={company.companyname}
                onChange={handleChange}
              />
              {validError.companyname ? (
                <span style={{ color: "red" }}>{validError.companyname}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("ŞirkətinVÖEN-i")}</label>
              <input
                type="text"
                style={
                  validError.companyvoen ? { border: "solid 1px red" } : {}
                }
                placeholder={t("VÖEN") + "..."}
                name="companyvoen"
                value={company.companyvoen}
                onChange={handleChange}
              />
              {validError.companyvoen ? (
                <span style={{ color: "red" }}>{validError.companyvoen}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Qeydiyyattarixi")}</label>
              <input
                type="text"
                style={
                  validError.companyregdate ? { border: "solid 1px red" } : {}
                }
                name="companyregdate"
                value={company.companyregdate}
                onChange={handleChange}
                placeholder="dd.MM.yyyy"
                required
              />
              {validError.companyregdate ? (
                <span style={{ color: "red" }}>
                  {validError.companyregdate}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Bitmətarixi")}</label>
              <input
                required
                type="text"
                style={
                  validError.companyexpiredate
                    ? { border: "solid 1px red" }
                    : {}
                }
                name="companyexpiredate"
                value={company.companyexpiredate}
                onChange={handleChange}
                placeholder="dd.MM.yyyy"
              />
              {validError.companyexpiredate ? (
                <span style={{ color: "red" }}>
                  {validError.companyexpiredate}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Maksimumistifadəçisayı")}</label>
              <input
                type="number"
                style={
                  validError.companymaxusercount
                    ? { border: "solid 1px red" }
                    : {}
                }
                placeholder={t("say") + "..."}
                name="companymaxusercount"
                value={company.companymaxusercount}
                onChange={handleChange}
              />
              {validError.companymaxusercount ? (
                <span style={{ color: "red" }}>
                  {validError.companymaxusercount}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{t("Daxilolduğuqrup")}</label>
              <select
                name="companygroupuserid"
                style={
                  validError.companygroupuserid
                    ? { border: "solid 1px red" }
                    : {}
                }
                onChange={handleChange}
                defaultValue={company.companygroupuserid}
              >
                <option value="0">{t("seçin") + "..."}</option>
                {groups.map(x => {
                  return (
                    <option key={x.userid} value={x.userid}>
                      {x.userfirstname}
                    </option>
                  );
                })}
              </select>
              {validError.companygroupuserid ? (
                <span style={{ color: "red" }}>
                  {validError.companygroupuserid}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button style={{ cursor: "pointer" }} onClick={handleClose}>
              {t("Ləğvet")}
            </button>
            <button style={{ cursor: "pointer" }} onClick={handleSubmit}>
              {t("Təsdiqet")}
            </button>
          </div>
        </div>
      ) : null}
      {modal3 ? (
        <div className="modal-right">
          <div className="modal-body">
            <div className="form-group">
              <label>{t("VÖEN")}:</label>
              <input
                type="text"
                placeholder={t("VÖEN") + "..."}
                name="CompaniesDataVoen"
                defaultValue={companyData.companiesDataVoen}
                onChange={e => handleChangeData(e)}
              />
            </div>
            <div className="form-group">
              <label>{t("Əsas bank hesabı:")}</label>
              <input
                type="text"
                placeholder={t("Əsas bank hesabı") + "..."}
                name="CompaniesDataMainBankAcoount"
                defaultValue={companyData.companiesDataMainBankAcoount}
                onChange={handleChangeData}
              />
            </div>
            <div className="form-group">
              <label>{t("Hesablaşma hesabı:")}</label>
              <input
                type="text"
                placeholder={t("Əsas bank hesabı") + "..."}
                name="CompaniesDataBankAcoount"
                defaultValue={companyData.companiesDataBankAcoount}
                onChange={handleChangeData}
              />
            </div>
            <div className="form-group">
              <label>{t("Direktorun adı, soyadı")}</label>
              <input
                type="text"
                placeholder={t("Direktorun adı, soyadı") + "..."}
                name="CompaniesDataFullName"
                defaultValue={companyData?.companiesDataFullName}
                onChange={handleChangeData}
              />
            </div>
            <div className="form-group">
              <label>{t("Hüquqi ünvan:")}</label>
              <input
                required
                type="text"
                name="CompaniesDataAdress"
                defaultValue={companyData?.companiesDataAdress}
                onChange={handleChangeData}
                placeholder="Hüquqi ünvan"
              />
            </div>
            <div className="form-group">
              <label>{t("Emailünvanı")}</label>
              <input
                type="text"
                placeholder={t("Emailünvanı") + "..."}
                name="CompaniesDataEmail"
                defaultValue={companyData?.companiesDataEmail}
                onChange={handleChangeData}
              />
            </div>
            <div className="form-group">
              <label>{t("Əlaqə nömrəsi")}</label>
              <input
                type="number"
                placeholder={"Azercell,Bakcell,Nar ... "}
                name="CompaniesDataPhoneAz"
                defaultValue={companyData?.companiesDataPhoneAz}
                onChange={handleChangeData}
              />
            </div>
            <div className="form-group">
              <label>{t("Əlaqə nömrəsi")}</label>
              <input
                type="number"
                placeholder={"Azercell,Bakcell,Nar..."}
                name="CompaniesDataPhoneBak"
                defaultValue={companyData?.companiesDataPhoneBak}
                onChange={handleChangeData}
              />
            </div>
            <div className="form-group">
              <label>{t("Əlaqə nömrəsi")}</label>
              <input
                type="number"
                placeholder={"Azercell,Bakcell,Nar..."}
                name="CompaniesDataPhoneNar"
                defaultValue={companyData?.companiesDataPhoneNar}
                onChange={handleChangeData}
              />
            </div>

            <div className="form-group">
              <label>{t("Rəhbərin vəzifəsi")}</label>
              <input
                type="text"
                placeholder={t("Rəhbərin vəzifəsi") + "..."}
                name="CompaniesDataPosition"
                defaultValue={companyData?.companiesDataPosition}
                onChange={handleChangeData}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button style={{ cursor: "pointer" }} onClick={handleClose3}>
              {t("Ləğvet")}
            </button>
            <button style={{ cursor: "pointer" }} onClick={SendHaqqinda}>
              {t("Təsdiqet")}
            </button>
          </div>
        </div>
      ) : null}
      {modalDelete ? (
        <div className="modal-delete">
          <div className="modal-body">
            <p>{t("Seçilmişsətrisilməkistədiyinizəəminsinizmi?")}</p>
            <p>
              {" "}
              {data.find(d => d.companyid === curRowId).companyname} -{" "}
              {data.find(d => d.companyid === curRowId).companyvoen}
            </p>
          </div>
          <div className="modal-footer">
            <button style={{ cursor: "pointer" }} onClick={handleClose}>
              {t("Ləğvet")}
            </button>
            <button style={{ cursor: "pointer" }} onClick={handleDelete}>
              {t("Təsdiqet")}
            </button>
          </div>
        </div>
      ) : null}
      <div className="context-menu">
        <ul>
          <li onClick={() => navigate("/rules/companyid/" + curRowId)}>
            <i className="fa-solid fa-shield-halved"></i> {t("Səlahiyyətlər")}
          </li>
          <li
            onClick={() => {
              setModal(true);
              setCurRowId(null);
            }}
          >
            <i className="fa-solid fa-plus"></i> {t("YeniŞirkət")}
          </li>

          {/* haqqinda */}
          <li
            onClick={() => {
              handleOpenData(curRowId);
            }}
          >
            <i class="fa-solid fa-circle-info"></i> {t("Haqqında")}
          </li>

          <li
            onClick={() => {
              setModal(true);
              const d = data.find(x => x.companyid === curRowId);
              setCompany({
                companyname: d.companyname,
                companyvoen: d.companyvoen,
                companyregdate: d.companyregdate,
                companyexpiredate: d.companyexpiredate,
                companymaxusercount: d.companymaxusercount,
                companygroupuserid: d.companygroupuserid,
              });
            }}
          >
            <i className="fa-regular fa-pen-to-square"></i> {t("Dəyiş")}
          </li>
          <li
            onClick={() => {
              setModalDelete(true);
            }}
          >
            <i className="fa-regular fa-trash-can"></i> {t("Sil")}
          </li>
        </ul>
      </div>
    </>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#2db686"}
        color={"#2db686"}
        title={"Reporto"}
        size={80}
      />
    </div>
  );
}

export default Companies;
