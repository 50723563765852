import AreaChart from "../../components/Charts/AreaChart";
import { useTranslation } from "react-i18next";
function Home() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h2 className="section-head">{t("Admin_Panel")}</h2>
      <div className="items">
        <div className="item">
          <div className="title">
            <h2>{t("Müştərisayı")}</h2>
            <span>296/ay</span>
          </div>
          <AreaChart
            color={"#E30174"}
            toolbar={false}
            xaxis={false}
            yaxis={false}
          />
        </div>
        <div className="item">
          <div className="title">
            <h2>{t("Girişstatistikası")}</h2>
            <span>5612</span>
          </div>
          <AreaChart />
        </div>
        <div className="item">
          <div className="title">
            <h2>{t("Girişstatistikası")}</h2>
            <span>5612</span>
          </div>
          <AreaChart />
        </div>
        <div className="item">
          <div className="title">
            <h2>{t("Girişstatistikası")}</h2>
            <span>5612</span>
          </div>
          <AreaChart />
        </div>
      </div>
    </div>
  );
}

export default Home;
