import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBakcUpType } from "../store/authSlice";
import { useTranslation } from "react-i18next";
import BackUpType from "./BackUpType";
function MobBackUpType() {
  const { t } = useTranslation();
  const backUpType = useSelector(state => state.auth.backUpType);
  const backtype = [
    {
      name: t("Last30Day"),
      value: 1,
    },
    {
      name: t("CurrentMonth"),
      value: 2,
    },
    {
      name: t("Last_month"),
      value: 3,
    },
  ];
  const dispatch = useDispatch();
  const _backUpType = e => {
    dispatch(changeBakcUpType({ backUpType: e.target.getAttribute("value") }));
  };
  return (
    <ul className="mob-company-select mob-back">
      {backtype.map(c => {
        return (
          <li key={c.value}>
            <label>
              <input
                type="radio"
                name="backtype"
                value={c.value}
                key={c.value}
                onClick={_backUpType}
                defaultChecked={parseInt(c.value) === parseInt(backUpType)}
              />
              {c.name}
            </label>
          </li>
        );
      })}
    </ul>
  );
}

export default MobBackUpType;
