import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NoPage from "./views/NoPage";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./i18n";
import Login from "./views/Login";
import ResetPassword from "./views/ResetPassword";
import SendTokenMail from "./views/SendTokenMail";
function Loginroute() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/sendtomail" element={<SendTokenMail />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default Loginroute;
