import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
const defaultLanguage = "az";
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: defaultLanguage,
    // debug: true,
    backend: {
      loadPath: `/locales/{{lng}}/translation.json`,
    },
  });
if (!localStorage.getItem("i18nextLng")) {
  i18n.changeLanguage(defaultLanguage);
}
export default i18n;
