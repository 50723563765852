import axios from "./axios";
import { useDispatch } from "react-redux";
import { resetTokens } from "../store/authSlice";
const useRefreshToken = () => {
  const dispatch = useDispatch();
  const refrestoken = JSON.parse(
    localStorage.getItem("authState")
  ).refreshToken;
  const refresh = async () => {
    const response = await axios.post(`auth/get/refreshtoken/${refrestoken}`, {
      withCredentials: true,
    });
    //authslice de accesstokeni yenile ve locala vur refresh tokeni
    dispatch(resetTokens(response.data.result));
    return response.data.result.accestoken;
  };
  return refresh;
};

export default useRefreshToken;
