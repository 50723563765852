import React from "react";
import Chart from "react-apexcharts";

function AreaChart({
  color,
  datas,
  labely = false,
  labelx = false,
  resheight = 300,
  icon = "₼",
}) {
  const series = [
    {
      name: "Balans",
      data: datas,
    },
  ];
  const options = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [color],
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      x: {
        format: "dd.MM.yyyy",
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: labely,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 526,
        options: {
          chart: {
            height: resheight,
          },
          yaxis: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      // height="100%"
    />
  );
}

export default AreaChart;

// {
//   x: "Jan, 2",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 3",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 4",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 5",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 6",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 7",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 8",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 9",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 10",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 11",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 12",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 13",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 14",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 15",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 16",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 17",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 18",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 19",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 20",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 21",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 22",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 23",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 24",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 25",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 26",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 27",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 28",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 29",
//   y: Math.round(Math.random() * 100),
// },
// {
//   x: "Jan, 30",
//   y: Math.round(Math.random() * 100),
// },
