import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../store/urlSlice";
import usePrivate from "../store/useAxiosPrivate2";

function Navadmin() {
  const { sendRequest } = usePrivate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const statusId = useSelector(state => state.url.status);
  // const [statusId, setStatusId] = useState(1);
  const token = useSelector(state => state.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest("GET", `auth/checkAccess`, null);
        console.log(res);
        dispatch(changeStatus({ status: res.status }));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
    // axios
    //   .get(`${url}auth/checkAccess`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then(res => {
    //     dispatch(changeStatus({ status: res.data.status }));
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  }, []);

  const links = {
    status_1: [
      {
        to: "/",
        text: t("Admin_Panel"),
        icon: "fa-solid fa-house",
      },
      {
        to: "/companygroups",
        text: t("Company_group"),
        icon: "fa-solid fa-layer-group",
      },
      {
        to: "/companies",
        text: t("Companies"),
        icon: "fa-solid fa-building",
      },
      {
        to: "/employees",
        text: t("Company_employees"),
        icon: "fa-solid fa-user",
      },
    ],
  };
  const [minimize, setMinimize] = useState(
    localStorage.getItem("minimized") == "true"
  );
  return (
    <>
      <nav className={minimize ? "minimized" : ""}>
        <div className="logo">
          <i
            className="fa fa-thumb-tack"
            onClick={() => {
              setMinimize(!minimize);
              localStorage.setItem("minimized", !minimize);
            }}
          ></i>
          <h1>Reporto</h1>
          <span>Lorem, ipsum dolor.</span>
        </div>

        <ul>
          {links["status_" + statusId].map(l => {
            return (
              <li key={l.to}>
                <NavLink to={l.to}>
                  <i className={l.icon}></i>
                  <span>{l.text}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default Navadmin;
