import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import urlSlice from "./urlSlice";

const rootReducer = {
  auth: authSlice,
  url: urlSlice,
};

const Store = configureStore({
  reducer: rootReducer,
});

export default Store;
