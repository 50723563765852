import { useDispatch, useSelector } from "react-redux";
import { loginStart, loginFailure } from "../store/authSlice";
import AuthService from "../store/AuthService";
import { Puff } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Flags from "../components/Flags";
import "../css/Login.css";
import { NavLink, useNavigate } from "react-router-dom";
let intervalId;
function timer(min, sec, callback) {
  intervalId = setInterval(() => {
    sec--;
    if (sec === -1) {
      min--;
      sec = 59;
    }
    if (min === 0 && sec === 0) {
      clearInterval(intervalId);
      callback();
    } else {
      let res = `Yeni müraciət üçün ${min < 10 ? "0" + min : min}:${
        sec < 10 ? "0" + sec : sec
      } gözləməlisiniz!!!`;
      callback(res);
    }
  }, 1000);
}
function SendTokenMail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => state.auth);
  const navigate = useNavigate();

  const handleForgetPassword = async e => {
    e.preventDefault();
    console.log(e.target.email.value);
    try {
      dispatch(loginStart());
      const response = await AuthService.sendPassword(e.target.email.value);
      console.log(response);
      if (!response.isSuccess) {
        // console.log(timer(4, 1));
        if (response.result?.minute != null) {
          timer(response.result.minute, response.result.second, result => {
            dispatch(loginFailure(result));
          });
        } else {
          dispatch(loginFailure(response.message));
        }
      } else {
        dispatch(loginFailure());
        navigate("/");
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };

  return (
    <div className="loginpg">
      {/* {<Flags />} */}
      <div className="card-login">
        <div className="card-login-text">
          <h1>REPORTO</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            porro deserunt repudiandae, quod quaerat cumque?
          </p>
        </div>
        <form method="post" onSubmit={handleForgetPassword}>
          <h2>{t("forgotPassword")}</h2>
          <div className="login-input">
            <label>{t("email")}</label>
            <input type="text" required name="email" placeholder={t("email")} />
          </div>
          <button disabled={loading} className="btn">
            {loading ? (
              <Puff
                visible={true}
                height="30"
                width="100%"
                color="#fff"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              t("Göndər")
            )}
          </button>
          {error && (
            <p className="text" style={{ color: "red" }}>
              {error}
            </p>
          )}
          <NavLink
            to="/"
            onClick={() => {
              clearInterval(intervalId);
              dispatch(loginFailure());
            }}
            style={{ textAlign: "center" }}
          >
            {t("Giriş")}
          </NavLink>
        </form>
      </div>
      <div></div>
    </div>
  );
}

export default SendTokenMail;
