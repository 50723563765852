import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
function PieChart({ s, l }) {
  const currency = useSelector(state => state.auth.currency);
  const series = s;

  const options = {
    labels: l,
    legend: {
      show: true,
      position: "bottom",
      height: 150,
      formatter: function (seriesName, opts) {
        var value = opts.w.globals.series[opts.seriesIndex];
        var formattedValue = value.toLocaleString("tr-TR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return (
          seriesName +
          ": " +
          `<span>${formattedValue}<i class="${currency.icon}" style="margin-left:2px"></i></span>`
        );
      },
    },
    responsive: [
      {
        breakpoint: 526,
        options: {
          legend: {
            show: true,
          },
          chart: {
            height: 600,
          },
        },
      },
    ],
    chart: {
      type: "pie",
      events: {
        dataPointMouseEnter: function () {
          return true; // Disable hover interaction
        },
      },
    },
    theme: {
      palette: "palette" + Math.ceil(Math.random() * 10),
    },
    series: {
      formatter: function (val, opts) {
        var formattedValue = val.toLocaleString("tr-TR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formattedValue;
      },
    },
  };

  return <Chart options={options} series={series} type="pie" height={"100%"} />;
}

export default PieChart;
