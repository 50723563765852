import React from "react";
function Error({ text, type }) {
  return type === 1 ? (
    <div className="bottom-right-success">{text}</div>
  ) : (
    <div className="bottom-right-error">Error: {text}</div>
  );
}

export default Error;
