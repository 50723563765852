import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { logout } from "../store/authSlice";
import { changeStatus } from "../store/urlSlice";
import usePrivate from "../store/useAxiosPrivate2";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
function CompanyData() {
  const { sendRequest } = usePrivate();
  const companies = useSelector(state => state.auth.companies);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const [companyData, setCompanyData] = useState();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const userFullName = useSelector(state => state.auth.userFullName);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(changeStatus({ status: null }));
  };
  const GetData = async () => {
    console.log(selectedCompany);
    try {
      const res = await sendRequest(
        "GET",
        `GetData/CompanyData/${selectedCompany}`,
        null
      );
      console.log(res);
      if (res) {
        console.log(res);
        console.log(companyData);
        setCompanyData(res);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    GetData();
  }, []);

  return (
    <div className="mob-settings">
      <WhatsappShareButton
        url={`
${t("Şirkətinadı")}: ${
          companies?.find(c => c.companyid === parseInt(selectedCompany))
            .companyname
        }
${t("esasBank")}${companyData?.companiesDataMainBankAcoount}
${t("hesablasma")}${companyData?.companiesDataBankAcoount}
${t("VÖEN")}: ${companyData?.companiesDataVoen}
${t("Hüquqiünvan")}${companyData?.companiesDataAdress}
${t("Emailünvanı")}: ${companyData?.companiesDataEmail}
${t("Əlaqənömrəsi")}: ${companyData?.companiesDataPhoneAz}
${t("Direktorunadısoyadı")}: ${companyData?.companiesDataFullName}
${t("rehber")}: ${companyData?.companiesDataPosition}`}
        quote={"Seisco MMC!"}
        hashtag="Siesco"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <div className="companyData">
        <ul className="companyDataFirstUl">
          <li>
            <span>{t("VÖEN")}:</span>
            <span>{companyData?.companiesDataVoen}</span>
          </li>
          <li>
            <span>{t("esasBank")}:</span>
            <span>{companyData?.companiesDataMainBankAcoount}</span>
          </li>
          <li>
            <span>{t("hesablasma")}:</span>
            <span>{companyData?.companiesDataBankAcoount}</span>
          </li>
          <li>
            <span>{t("Hüquqiünvan")}:</span>
            <span>{companyData?.companiesDataAdress}</span>
          </li>
          <li>
            <span>{t("Emailünvanı")}:</span>
            <span>{companyData?.companiesDataEmail}</span>
          </li>
          <li>
            <span> {t("Əlaqənömrəsi")}:</span>
            <span>{companyData?.companiesDataPhoneAz}</span>
          </li>
          <li>
            <span> {t("Direktorunadısoyadı")}:</span>
            <span>{companyData?.companiesDataFullName}</span>
          </li>
          <li>
            <span> {t("rehber")}:</span>
            <span>{companyData?.companiesDataPosition}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CompanyData;
