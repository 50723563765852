import { React, useState } from "react";
import Nav from "./Navadmin";
import { Outlet } from "react-router-dom";
import "../css/Layout.css";
import "../css/Table.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";
import { changeStatus } from "../store/urlSlice";
import Flags from "./Flags";
import { useTranslation } from "react-i18next";
function Layout() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(changeStatus({ status: null }));
  };
  const userFullName = useSelector(state => state.auth.userFullName);
  return (
    <div style={{ display: "flex" }}>
      <Nav />
      <main>
        <header>
          <ul className="webheader">
            {/* <li>
              <i className="fa-regular fa-bell"></i>
            </li> */}
            <li
              className="profile"
              onClick={() => {
                document
                  .querySelector(".profile-card")
                  .classList.toggle("profile-card-show");
              }}
            >
              <i className="fa-solid fa-id-card"></i>
              <span>{userFullName}</span>
              <div className="profile-card">
                {<Flags />}

                <button onClick={handleLogout}>{t("Logout")}</button>
              </div>
            </li>
          </ul>
        </header>
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
