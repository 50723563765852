import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrency } from "../store/authSlice";
import usePrivate from "../store/useAxiosPrivate2";
// const backtype = [
//   {
//     icon: "fa-solid fa-manat-sign",
//     value: 1.0,
//     id: 1,
//     text: "",
//   },
//   {
//     icon: "fa-solid fa-dollar-sign",
//     value: 1.7,
//     id: 3,
//     text: "Usd",
//   },
//   {
//     icon: "fa-solid fa-euro-sign",
//     value: 1.8,
//     id: 2,
//     text: "Avro",
//   },
// ];

function Valyuta() {
  const { sendRequest } = usePrivate();
  const currency = useSelector(state => state.auth.currency);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [db, setdb] = useState();

  const modalRef = useRef(null);

  const handleOutsideClick = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModal(false);
    }
  };
  const fetchData = async () => {
    try {
      const res = await sendRequest(
        "GET",
        `GetData/GetCurrency/${selectedCompany}`,
        null
      );
      console.log(res.result);
      console.log(
        Number(parseFloat(res.result.companiesCurrencyAvro)).toFixed(2, 3)
      );

      setdb([
        {
          icon: "fa-solid fa-manat-sign",
          value: 1.0,
          id: 1,
          text: "",
        },
        {
          icon: "fa-solid fa-dollar-sign",
          value: parseFloat(res.result.companiesCurrencyUsd),
          id: 3,
          text: "Usd",
        },
        {
          icon: "fa-solid fa-euro-sign",
          value: parseFloat(res.result.companiesCurrencyAvro),
          id: 2,
          text: "Avro",
        },
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleChangeCompany = Id => {
    dispatch(changeCurrency({ currency: Id }));
    setModal(false);
  };

  return (
    <div className="companyselect" ref={modalRef}>
      <div
        className={
          modal ? "company-button actv Valyuta" : "company-button Valyuta"
        }
        onClick={() => setModal(!modal)}
      >
        <h3 style={{ marginRight: "10px" }}>
          <span className="valyuta-icon">
            {db !== null ? (
              <i className={db?.find(c => c.id === currency.id).icon}></i>
            ) : (
              ""
            )}
          </span>
          {db !== null
            ? Number(db?.find(c => c.id === currency.id).value).toFixed(2, 3)
            : ""}
        </h3>
        <i className="fa-solid fa-caret-down" style={{ color: "white" }}></i>
      </div>
      {modal ? (
        <div className="modalselectcompany vlyuta">
          {db.map(c => (
            <span
              key={c.id}
              onClick={() => handleChangeCompany(c)}
              style={{ color: "white" }}
            >
              <i className={c.icon}></i>
              {Number(parseFloat(c.value)).toFixed(2, 3)}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default Valyuta;
