import React from 'react'
import '../css/Access.css'
function Access() {
    return (
        <div className='access'>
            <div className="page-wrap">
                <div className="page-not-found">
                    <img src="https://res.cloudinary.com/razeshzone/image/upload/v1588316204/house-key_yrqvxv.svg" className="img-key" alt="" />
                    <h1 className="text-xl">
                        <span>4</span>
                        <span>0</span>
                        <span className="broken">3</span>
                    </h1>
                    <h4 className="text-md">İcazəsiz giriş !</h4>
                    <h4 className="text-sm text-sm-btm">Daxil olmaq istədiyiniz səhifədəki məlumatları görmək üçün icazəniz yoxdur. </h4>
                </div>
            </div>
        </div>
    )
}

export default Access