import { useDispatch, useSelector } from "react-redux";
import { loginStart, loginSuccess, loginFailure } from "../store/authSlice";
import AuthService from "../store/AuthService";
import { Puff } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import "../css/Login.css";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [logshow, setLogshow] = useState(1);
  const { loading, error } = useSelector(state => state.auth);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      setLogshow(3);
    }
  }, []);
  const handleLogin = async e => {
    e.preventDefault();
    try {
      dispatch(loginStart());
      const response = await AuthService.login(
        e.target.username.value,
        e.target.password.value
      );
      console.log(response);
      dispatch(loginSuccess(response));
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
  const handleForgetPassword = async e => {
    e.preventDefault();
    try {
      dispatch(loginStart());
      const response = await AuthService.sendPassword(e.target.email.value);
      if (!response.isSuccess) {
        dispatch(loginFailure(response.message));
      } else {
        setLogshow(1);
        dispatch(loginFailure());
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
  const handleChangePassword = async e => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    try {
      dispatch(loginStart());
      const response = await AuthService.changePassword(
        token,
        e.target.password.value
      );
      if (!response.isSuccess) {
        dispatch(loginFailure(response.message));
      } else {
        setLogshow(1);
        dispatch(loginFailure());
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
  return (
    <div className="loginpg">
      {/* {<MobFlags />} */}
      <div className="card-login">
        <div className="card-login-text">
          <h1>REPORTO</h1>
          <p>
            Lo`rem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            porro deserunt repudiandae, quod quaerat cumque?
          </p>
        </div>

        <form method="post" onSubmit={handleLogin}>
          <h2>{t("Giriş")}</h2>
          <div className="login-input">
            <label>{t("İstifadəçiadı")}</label>
            <input
              type="text"
              required
              name="username"
              placeholder={t("İstifadəçiadı")}
            />
          </div>
          <div className="login-input">
            <label>{t("password")}</label>
            <input
              type="password"
              required
              name="password"
              placeholder={t("password")}
            />
          </div>
          <button disabled={loading} className="btn">
            {loading ? (
              <Puff
                visible={true}
                height="30"
                width="100%"
                color="#fff"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              t("Login")
            )}
          </button>
          {error && (
            <p className="text" style={{ color: "red" }}>
              {error}
            </p>
          )}
          <NavLink to="/sendtomail" style={{ textAlign: "center" }}>
            {t("forgotPassword")}
          </NavLink>
        </form>
      </div>
      <div></div>
    </div>
  );
}

export default Login;
