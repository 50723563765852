import React, { useState, useEffect, useRef } from "react";
import Nav from "./Nav";
import { NavLink, Outlet } from "react-router-dom";
import "../css/Layout.css";
import "../css/Table.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";
import { changeStatus, changeSidebar } from "../store/urlSlice";
import Flags from "./Flags";
import SelectCompany from "./SelectCompany";
import styled from "styled-components";
import BackUpType from "./BackUpType";
import Modal from "./Modal";
import MobSelectCompany from "./mobSelectCompany";
import MobBackUpType from "./MobBackUpType";
import MobSettings from "./MobSettings";
// import BacktypeToogle from "./BacktypeToogle";
import Valyuta from "./Valyuta";
import { useTranslation } from "react-i18next";
import CompanyData from "./CompanyData";
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 18px 18px 0 0;
  padding: 20px 24px;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;
function Layout() {
  const fullScreenRef = useRef(null);
  const { t } = useTranslation();
  const userFullName = useSelector(state => state.auth.userFullName);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const backUpType = useSelector(state => state.auth.backUpType);
  const minimize = useSelector(state => state.url.sidebar);
  const [mobValue, setmobmodal] = useState(1);
  const [modal, setModal] = useState(false);
  const [isOpen, toggle] = useState(false);
  const modalRef = useRef(null);
  function handlOpenModal(open) {
    toggle(open);
  }
  const toggleFullScreen = () => {
    if (fullScreenRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        fullScreenRef.current.requestFullscreen();
      }
    }
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(changeStatus({ status: null }));
  };

  useEffect(() => {
    responsiverender();
    MobMenu();
    // responsivModal();
  }, [window.innerWidth]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside of modal, close it
        setModal(false);
      }
    };
  }, []);

  //   // Add event listener when the modal is open
  //   if (modal) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     // Remove event listener when the modal is closed
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [modal]);
  const MobMenu = () => {
    if (window.innerWidth <= 526) {
      return (
        <div className="mob-bottom-menu">
          <div style={{ position: "relative" }} className="modalContainer">
            <Modal
              isOpen={isOpen}
              handleClose={() => handlOpenModal(false)}
              showd={"none"}
            >
              <ModalContent>
                <div className="modalTitle">
                  <h1>
                    {mobValue === 1
                      ? "Şirkət seçin"
                      : mobValue === 2
                      ? "Aralalıq seçin"
                      : mobValue === 4
                      ? "Ayarlar"
                      : mobValue === 3
                      ? "Haqqında"
                      : ""}
                  </h1>
                  <button onClick={() => toggle(false)}>
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                {mobValue === 1 && selectedCompany !== 0 ? (
                  <MobSelectCompany />
                ) : null}
                {mobValue === 2 && backUpType !== 0 ? <MobBackUpType /> : null}
                {mobValue === 4 ? <MobSettings /> : null}
                {mobValue === 3 ? <CompanyData /> : null}
              </ModalContent>
            </Modal>
          </div>
          <ul className="mob_menuul">
            <li>
              <i
                className="fa-solid fa-building"
                onClick={e => {
                  handlOpenModal(true);
                  setmobmodal(1);
                }}
              ></i>
            </li>
            <li>
              <i
                className="fa-solid fa-calendar-days"
                onClick={e => {
                  handlOpenModal(true);
                  setmobmodal(2);
                }}
              ></i>
            </li>
            <li className="mob-bottom-home">
              <NavLink to={"/"}>
                <img
                  src="/icons/home-icon-01.svg"
                  style={{
                    width: "25px",
                    height: "25px",
                    transform: "translateY(4px)",
                    filter: "drop-shadow(2px 4px 6px black)",
                  }}
                ></img>
              </NavLink>
            </li>
            <li>
              <i
                className="fa-solid fa-coins"
                onClick={e => {
                  handlOpenModal(true);
                  setmobmodal(3);
                }}
              ></i>
            </li>
            <li>
              <i
                className="fa-solid fa-gear"
                onClick={e => {
                  handlOpenModal(true);
                  setmobmodal(4);
                }}
              ></i>
            </li>
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const responsiverender = () => {
    if (window.innerWidth <= 526) {
      return (
        <ul className="mobheader">
          <li>
            <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>
              Reporto
            </NavLink>
          </li>
          <li>
            <i
              className="fa-solid fa-bars"
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.setItem("minimized", !minimize);
                dispatch(changeSidebar({ sidebar: !minimize }));
              }}
            ></i>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="webheader">
          <li style={{ marginRight: "auto", display: "flex" }}>
            {selectedCompany !== 0 ? <SelectCompany /> : null}
            {backUpType !== 0 ? <BackUpType /> : null}
            {<Valyuta />}
          </li>
          <li>
            <i className="fa-solid fa-expand" onClick={toggleFullScreen}></i>
          </li>
          <li
            className="profile"
            onClick={() => {
              document
                .querySelector(".profile-card")
                .classList.toggle("profile-card-show");
            }}
          >
            <i className="fa-solid fa-id-card"></i>
            <span>{userFullName}</span>
            <div className="profile-card">
              {<Flags />}

              <button onClick={handleLogout}>{t("Logout")}</button>
            </div>
          </li>
        </ul>
      );
    }
  };

  return (
    <div ref={fullScreenRef} style={{ display: "flex" }}>
      <Nav />
      <main>
        <header
          style={
            minimize
              ? { width: "calc(100% - 100px)" }
              : { width: "calc(100% - 356px)" }
          }
        >
          {responsiverender()}
        </header>
        <div>
          <Outlet /> {/* Render the Outlet component directly */}
          {MobMenu()}
        </div>
      </main>
    </div>
  );
}

export default Layout;
