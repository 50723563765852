import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LineChart from "../../components/Charts/LineChart";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import PieChart from "../../components/Charts/PieChart";
import MainError from "../../components/Notifications/MainError";
import { changeNot } from "../../store/urlSlice";
import usePrivate from "../../store/useAxiosPrivate2";

function Inventory() {
  const currency = useSelector(state => state.auth.currency);
  const { sendRequest } = usePrivate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notification = useSelector(state => state.url.notification);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const BackUpType = useSelector(state => state.auth.backUpType);
  const [db, setdb] = useState(null);
  const [type, setType] = useState("chart");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest(
          "GET",
          `GetData/Inventory/${selectedCompany}/${BackUpType}`,
          null
        );

        dispatch(changeNot({ type: res.isSuccess, message: res.message }));
        setdb(res);
      } catch (err) {
        console.error(err);
        dispatch(changeNot({ type: err.isSuccess, message: err.message }));
        console.log(err);
      }
    };

    fetchData();
  }, [BackUpType, selectedCompany]);

  return db !== null ? (
    db.isSuccess ? (
      db.result.group.length > 0 ? (
        <div className="cash-detail">
          <div className="cash-head">
            <h3>
              {t("QaliqMebleq")}:{" "}
              {db.isSuccess
                ? db.result.history[db.result.history.length - 1][
                    `sum${currency.text}`
                  ].toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : null}
              <i className={currency.icon}></i>
            </h3>
            <ul className="show-type">
              <li
                className={type !== "table" ? "active" : "hide"}
                onClick={() => setType("table")}
              >
                <span>{t("Cədvəlgörünüşü")}</span>
                <i className="fa-solid fa-bars"></i>
              </li>
              <li
                className={type !== "chart" ? "active" : "hide"}
                onClick={() => setType("chart")}
              >
                <span>{t("Qrafikgörünüşü")}</span>
                <i className="fa-solid fa-chart-line"></i>
              </li>
            </ul>
          </div>
          <div className="pie">
            {type === "chart" ? (
              <PieChart
                s={db.result.group.map(d => d[`sum${currency.text}`])}
                l={db.result.group.map(d => d.inventorybranchname)}
              />
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>{t("Anbar")}</th>
                    <th>{t("Məbləğ")}</th>
                  </tr>
                </thead>
                <tbody>
                  {db.result.group.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{d.inventorybranchname}</td>
                        <td className="textal">
                          {d[`sum${currency.text}`].toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          <i className={currency.icon}></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <div className="area">
            {type === "chart" ? (
              <LineChart
                data={db.result.history.map(d => {
                  return {
                    x: d.date,
                    y: d[`sum${currency.text}`].toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                  };
                })}
                height="100%"
                toolbar={true}
                labelx={true}
                icon={currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "€"}
                labely={true}
              />
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>{t("Anbar")}</th>
                    <th>{t("Qrupu")}</th>
                    <th>{t("Adı")}</th>
                    <th>{t("Miqdarı")}</th>
                    <th>{t("Vahid")}</th>
                    <th>{t("Məbləğ")}</th>
                  </tr>
                </thead>
                <tbody>
                  {db.result.today.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{d.inventorybranchname}</td>
                        <td>{d.inventoryproductgroup}</td>
                        <td>{d.inventoryproductname} </td>
                        <td className="textal">{d.inventoryquantity}</td>
                        <td>{d.inventoryunit}</td>
                        <td className="textal">
                          {d[`inventoryamount${currency.text}`].toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          <i className={currency.icon}></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <div style={{ padding: "45px 13px" }}>
          <h2 className="section-head" style={{ marginLeft: "20px" }}>
            {t("Məlumatyoxdur")}
          </h2>
        </div>
      )
    ) : (
      <div style={{ padding: "45px 13px" }}>
        <h2 className="section-head" style={{ marginLeft: "20px" }}>
          {t("Məlumatyoxdur")}
        </h2>
        {notification ? <MainError /> : null}
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#0d2e4e"}
        color={"#0d2e4e"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  );
}

export default Inventory;
