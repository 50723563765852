import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBakcUpType } from "../store/authSlice";
import { useTranslation } from "react-i18next";
function BackUpType() {
  const { t } = useTranslation();
  const backUpType = useSelector(state => state.auth.backUpType);
  const backtype = [
    {
      name: t("Last30Day"),
      value: 1,
    },
    {
      name: t("CurrentMonth"),
      value: 2,
    },
    {
      name: t("Last_month"),
      value: 3,
    },
  ];
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const _backUpType = e => {
    dispatch(changeBakcUpType({ backUpType: e.target.getAttribute("value") }));
    setModal(false);
  };
  return (
    <ul className="backtypeselect">
      {backtype
        ? backtype.map(c => {
            return (
              <li
                className={
                  backUpType == c.value ? "back-items act" : "back-items"
                }
                key={c.value}
              >
                <span
                  key={c.value}
                  style={{ cursor: "pointer" }}
                  onClick={_backUpType}
                  value={c.value}
                >
                  {c.name}
                </span>
              </li>
            );
          })
        : null}
    </ul>
  );
}

export default BackUpType;
