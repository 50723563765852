import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
function ColumnChart({
  data,
  color,
  height,
  resheight = 300,
  labelx = false,
  labely = false,
  icon = "₼",
}) {
  const currency = useSelector(state => state.auth.currency);
  // const ic = currency.id === 1 ? "₼" : currency.id === 3 ? "$" : "@";
  const series = [
    {
      name: "Balans",
      data: data.map(d => ({ x: d.x, y: d[`y${currency.text}`] })),
    },
  ];
  const options = {
    colors: color,
    responsive: [
      {
        breakpoint: 526,
        options: {
          chart: {
            height: resheight,
          },
          yaxis: {
            show: true,
          },
        },
      },
    ],
    chart: {
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    fill: {
      // colors: ["red"],
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
        show: labelx,
      },
    },
    yaxis: {
      labels: {
        formatter: value => {
          return value.toLocaleString("tr-TR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
      show: labely,
    },
  };

  return (
    <Chart
      type="bar"
      options={options}
      series={series}
      width="100%"
      height={height}
    />
  );
}

export default ColumnChart;
