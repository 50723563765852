import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeCompany, changeMenu, changeCompanies } from "../store/authSlice";
import { changeStatus } from "../store/urlSlice";
import "../css/Layout.css";
import { useTranslation } from "react-i18next";
import usePrivate from "../store/useAxiosPrivate2";
import Modal from "./ModalInfo";
import styled from "styled-components";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Layout from "./Layout";
const ModalContent = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  border-radius: 18px 18px 0 0;
  padding: 20px 24px;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;
function SelectCompany() {
  const { sendRequest } = usePrivate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const companies = useSelector(state => state.auth.companies);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [infoopen, setinfoopen] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [share, setshare] = useState(false);
  const fetchData = async e => {
    try {
      const res = await sendRequest(
        "GET",
        `GetData/GetRoute/${e.target.getAttribute("value")}`,
        null
      );
      dispatch(changeStatus({ status: res.status }));
      if (res.status === 5) {
        dispatch(changeCompanies({ companies: res.company }));
        dispatch(changeMenu({ menu: res.menu }));

        if (res.menu.length === 0) {
          dispatch(
            changeCompany({
              selectedCompany: parseInt(res.company[0].companyid),
            })
          );
          navigate("/");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  const GetData = async () => {
    console.log(selectedCompany);
    try {
      const res = await sendRequest(
        "GET",
        `GetData/CompanyData/${selectedCompany}`,
        null
      );
      console.log(res);
      if (res) {
        console.log(res);
        console.log(companyData);
        setCompanyData(res);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (infoopen) {
      GetData();
    }
  }, [infoopen]);
  const _changeCompany = e => {
    fetchData(e);
    dispatch(
      changeCompany({ selectedCompany: e.target.getAttribute("value") })
    );
    setModal(false);
  };
  return (
    <div className="companyselect">
      <div className="CompanyInfoD" onClick={() => setinfoopen(true)}>
        <i class="fa-solid fa-circle-info"></i>
      </div>
      <div
        className={modal ? "company-button actv" : "company-button"}
        onClick={() => setModal(!modal)}
      >
        <h3 style={{ marginRight: "10px" }}>
          {selectedCompany !== null
            ? companies?.find(c => c.companyid === parseInt(selectedCompany))
                .companyname
            : ""}
        </h3>
        <i className="fa-solid fa-caret-down slct_icon"></i>
      </div>
      {modal ? (
        <div className="modalselectcompany">
          {companies.map(c => {
            return (
              <span
                key={c.companyid}
                onClick={_changeCompany}
                value={parseInt(c.companyid)}
              >
                {c.companyname}
              </span>
            );
          })}
        </div>
      ) : null}
      <Modal
        isOpen={infoopen}
        handleClose={() => setinfoopen(false)}
        showd={"none"}
      >
        <ModalContent>
          <div
            className="modalInfoTitle"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0 0 25px 0",
            }}
          >
            <h1 style={{ color: "#11507b" }}>
              {" "}
              {selectedCompany !== null
                ? companies?.find(
                    c => c.companyid === parseInt(selectedCompany)
                  ).companyname
                : ""}
            </h1>
            <div
              className="CmButtons"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i
                class="fa-solid fa-square-share-nodes"
                style={{
                  fontSize: "50px",
                  marginRight: "0",
                  width: "100%",
                  color: "#009cff",
                  marginRight: "5px",
                }}
                onClick={() => setshare(true)}
              ></i>
              <i
                class="fa-solid fa-xmark"
                style={{
                  fontSize: "25px",
                  marginRight: "0",
                  width: "100%",
                  color: "#009cff",
                  marginRight: "5px",
                  border: "1px solid #11507b",
                  borderRadius: "10px",
                  padding: "4px 12px",
                }}
                onClick={() => setinfoopen(false)}
              ></i>
            </div>
          </div>
          <div className="companyData">
            <ul className="companyDataFirstUl">
              <li>
                <span>
                  <i class="fa-solid fa-id-card-clip"></i>
                  {t("VÖEN")}:
                </span>
                <span className="SCSpan">{companyData?.companiesDataVoen}</span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-building-columns"></i>
                  {t("esasBank")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataMainBankAcoount
                    ? companyData?.companiesDataMainBankAcoount
                    : "yoxdur"}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-building-columns"></i>
                  {t("hesablasma")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataBankAcoount}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-address-book"></i>
                  {t("Hüquqiünvan")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataAdress}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-phone"></i>
                  {t("Emailünvanı")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataEmail}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-envelope"></i>
                  {t("Əlaqənömrəsi")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataPhoneAz}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-user-tie"></i>
                  {t("Direktorunadısoyadı")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataFullName}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa-solid fa-certificate"></i>
                  {t("rehber")}:
                </span>
                <span className="SCSpan">
                  {companyData?.companiesDataPosition}
                </span>
              </li>
            </ul>

            {/* <ul className="companyDataSecondUl">
              <li>{companyData?.companiesDataVoen} </li>
              <li>
                {companyData?.companiesDataMainBankAcoount
                  ? companyData?.companiesDataMainBankAcoount
                  : "yoxdur"}
              </li>
              <li>{companyData?.companiesDataBankAcoount}</li>
              <li>{companyData?.companiesDataAdress}</li>
              <li>{companyData?.companiesDataEmail}</li>
              <li>{companyData?.companiesDataPhoneAz}</li>
              <li>{companyData?.companiesDataFullName}</li>
              <li>{companyData?.companiesDataPosition}</li>
            </ul> */}
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={share} handleClose={() => setshare(false)} showd={"none"}>
        <ModalContent>
          <div
            className="modalInfoTitle"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0 0 25px 0",
            }}
          >
            <h1 style={{ color: "#11507b" }}>
              {" "}
              {selectedCompany !== null
                ? companies?.find(
                    c => c.companyid === parseInt(selectedCompany)
                  ).companyname
                : ""}
            </h1>
            <div
              className="CmButtons"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i
                class="fa-solid fa-xmark"
                style={{
                  fontSize: "25px",
                  marginRight: "0",
                  width: "100%",
                  color: "#009cff",
                  marginRight: "5px",
                  border: "1px solid #11507b",
                  borderRadius: "10px",
                  padding: "4px 12px",
                }}
                onClick={() => setshare(false)}
              ></i>
            </div>
          </div>
          <div className="companyData">
            {/* <FacebookShareButton
              url={"https://siesco.az/"}
              quote={"Seisco MMC!"}
              hashtag="Siesco"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton> */}
            <WhatsappShareButton
              url={`
${t("Şirkətinadı")}: ${
                companies?.find(c => c.companyid === parseInt(selectedCompany))
                  .companyname
              }
${t("esasBank")}${companyData?.companiesDataMainBankAcoount}
${t("hesablasma")}${companyData?.companiesDataBankAcoount}
${t("VÖEN")}: ${companyData?.companiesDataVoen}
${t("Hüquqiünvan")}${companyData?.companiesDataAdress}
${t("Emailünvanı")}: ${companyData?.companiesDataEmail}
${t("Əlaqənömrəsi")}: ${companyData?.companiesDataPhoneAz}
${t("Direktorunadısoyadı")}: ${companyData?.companiesDataFullName}
${t("rehber")}: ${companyData?.companiesDataPosition}`}
              quote={"Seisco MMC!"}
              hashtag="Siesco"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default SelectCompany;
