import React from "react";

function NoPage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "25%",
      }}
    >
      <h1>NoPage</h1>
    </div>
  );
}

export default NoPage;
