import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeCompany, changeMenu, changeCompanies } from "../store/authSlice";
import { changeStatus } from "../store/urlSlice";
import ApiService from "../store/ApiService";
import usePrivate from "../store/useAxiosPrivate2";

function MobSelectCompany() {
  const { sendRequest } = usePrivate();
  const navigate = useNavigate();
  const companies = useSelector(state => state.auth.companies);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const _changeCompany = async e => {
    try {
      const res = await sendRequest(
        "GET",
        `GetData/GetRoute/${e.target.getAttribute("value")}`,
        null
      );
      console.log(res);
      dispatch(changeStatus({ status: res.status }));
      if (res.status === 5) {
        dispatch(changeCompanies({ companies: res.company }));
        dispatch(changeMenu({ menu: res.menu }));

        if (res.menu.length === 0) {
          dispatch(
            changeCompany({
              selectedCompany: parseInt(res.company[0].companyid),
            })
          );
          navigate("/");
        }
      }
    } catch (err) {
      console.error(err);
    }
   

    dispatch(
      changeCompany({ selectedCompany: e.target.getAttribute("value") })
    );
  };
  function handleSelect(company) {
    // console.log(company)
    setSelectedOption(company)
  }
  return (
    <ul className="mob-company-select modalselect">
      {companies &&
        companies.map(c => {
          return (
            <li
              key={c.companyid}
              className={c.companyid === selectedCompany || selectedOption===c.companyname?"activeOption":""}
              //   onClick={_changeCompany}
              //   value={parseInt(c.companyid)}
            >
              <label>
                <input
                  type="radio"
                  name="companySelection"
                  // checked={selectedOption===c.companyname}
                  value={parseInt(c.companyid)}
                  onClick={e => _changeCompany(e)}
                  defaultChecked={parseInt(c.companyid) === selectedCompany}
                  onChange={()=>handleSelect(c.companyname)}
                />
                {c.companyname}
              </label>
            </li>
          );
        })}
    </ul>
  );
}

export default MobSelectCompany;
