import { useDispatch, useSelector } from "react-redux";
import { loginStart, loginFailure } from "../store/authSlice";
import AuthService from "../store/AuthService";
import { Puff } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Flags from "../components/Flags";
import "../css/Login.css";
import { useEffect } from "react";
import { useNavigate, NavLink, useParams } from "react-router-dom";

function ResetPassword() {
  const { token } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector(state => state.auth);
  useEffect(() => {
    check();
    console.log(token);
  }, []);
  const check = async () => {
    try {
      dispatch(loginStart());
      const response = await AuthService.CheckToken(token);
      console.log(response);
      if (!response.isSuccess) {
        dispatch(loginFailure(response.message));
        navigate("/");
      } else {
        dispatch(loginFailure());
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
      navigate("/");
    }
  };

  const handleChangePassword = async e => {
    e.preventDefault();
    let name = e.target.password.value;
    let coname = e.target.confirmpassword.value;
    console.log(token);
    if (name == coname) {
      try {
        dispatch(loginStart());
        const response = await AuthService.changePassword(
          token,
          e.target.password.value
        );
        console.log(response);
        if (!response.isSuccess) {
          dispatch(loginFailure(response.message));
        } else {
          navigate("/");
          dispatch(loginFailure());
        }
      } catch (error) {
        dispatch(loginFailure(error.message));
      }
    } else {
      dispatch(loginFailure("Passwords do not match"));
    }
  };
  return (
    <div className="loginpg">
      {/* {<Flags />} */}
      <div className="card-login">
        <div className="card-login-text">
          <h1>REPORTO</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            porro deserunt repudiandae, quod quaerat cumque?
          </p>
        </div>
        <form method="post" onSubmit={handleChangePassword}>
          <h2>{t("changePassword")}</h2>
          <div className="login-input">
            <label>{t("password")}</label>
            <input
              type="password"
              required
              name="password"
              placeholder={t("password")}
            />
          </div>
          <div className="login-input">
            <label>{t("confirmPassword")}</label>
            <input
              type="password"
              required
              name="confirmpassword"
              placeholder={t("confirmPassword")}
            />
          </div>
          <button disabled={loading} className="btn">
            {loading ? (
              <Puff
                visible={true}
                height="30"
                width="100%"
                color="#fff"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              t("Yenilə")
            )}
          </button>
          {error && (
            <p className="text" style={{ color: "red" }}>
              {error}
            </p>
          )}
          <NavLink
            to="/"
            onClick={() => {
              dispatch(loginFailure());
            }}
            style={{ textAlign: "center" }}
          >
            {t("Giriş")}
          </NavLink>
        </form>
      </div>
      <div></div>
    </div>
  );
}

export default ResetPassword;
