import axios from "axios";
// const BASE_URL = "https://localhost:5062/api/";

const BASE_URL = "https://reportoapi.kursline.az/api/";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", accept: "text/plain" },
  // withCredentials: true,
});
