import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { logout } from "../store/authSlice";
import { changeStatus } from "../store/urlSlice";
import Flags from "./Flags";
import { changeCurrency } from "../store/authSlice";
import usePrivate from "../store/useAxiosPrivate2";
function MobSettings() {
  const [show, setShow] = useState(false);
  const { sendRequest } = usePrivate();
  const { t } = useTranslation();
  const userFullName = useSelector(state => state.auth.userFullName);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const currency = useSelector(state => state.auth.currency);
  const dispatch = useDispatch();
  const [db, setdb] = useState();
  const [active, setactive] = useState(1);
  const handleLogout = () => {
    dispatch(logout());
    dispatch(changeStatus({ status: null }));
  };
  const fetchData = async () => {
    try {
      const res = await sendRequest(
        "GET",
        `GetData/GetCurrency/${selectedCompany}`,
        null
      );
      setactive(currency.id);

      setdb([
        {
          icon: "fa-solid fa-manat-sign",
          value: 1.0,
          id: 1,
          text: "",
        },
        {
          icon: "fa-solid fa-dollar-sign",
          value: parseFloat(res.result.companiesCurrencyUsd),
          id: 3,
          text: "Usd",
        },
        {
          icon: "fa-solid fa-euro-sign",
          value: parseFloat(res.result.companiesCurrencyAvro),
          id: 2,
          text: "Avro",
        },
      ]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
    console.log();
  }, []);
  const handleChangeCompany = Id => {
    dispatch(changeCurrency({ currency: Id }));
    console.log(Id.id);
    setactive(Id.id);
  };

  return (
    <ul className="mob-settings">
      <li className="profile">
        <p>
          <i
            className="fa-solid fa-id-card"
            style={{ marginRight: "10px" }}
          ></i>
          <span>{userFullName}</span>
        </p>
        <button onClick={handleLogout} className="outButton">
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
          {/* {t("Logout")} */}
        </button>
      </li>
      <li>{<Flags />}</li>
      <li className={!show ? "ssshow" : "hhhide"}>
        <ul className={"currency " + (!show ? "" : "currency_hide")}>
          {db?.map(c => (
            <li
              className={
                active == c.id ? "currencyActiv  currency" : "currency"
              }
              key={c.id}
              onClick={() => handleChangeCompany(c)}
            >
              <i className={c.icon}></i>
              <span>
                {Number(parseFloat(c.value)).toFixed(2, 3)}
                <i className="fa-solid fa-arrow-up"></i>
              </span>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}

export default MobSettings;
