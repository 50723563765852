import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../css/Nav.css";
import { useNavigate } from "react-router-dom";
import { changeSidebar } from "../store/urlSlice";

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const menus = useSelector(state => state.auth.menu);
  const menu = useSelector(state => state.url.menu);
  const statusId = useSelector(state => state.url.status);
  const sidebar = useSelector(state => state.url.sidebar);
  const [routes, setRoutes] = useState([]);
  const [activeLogo, setActiveLogo] = useState("/")
  const dispatch = useDispatch();
  useEffect(() => {
    if (statusId === 5) {
      const rolekeysToFilterArray = menus.map(item => item.rulekey);
      const filtermenu = menu.filter(item =>
        rolekeysToFilterArray.includes(item.rolekey)
      );
      const key = filtermenu.findIndex(x => x.to === location.pathname);
      if (key === -1) {
        navigate("/");
      }
      setRoutes(filtermenu);
    }
  }, [menus]);

  const links = {
    status_2: [
      {
        to: "/",
        text: t("Home"),
        icon: "home-icon-01.svg",
        activeIcon:"home-icon-blue-01.svg"
      },
      {
        to: "detail/Cash",
        text: t("Kassa"),
        icon: "budget-icon-01.svg",
        activeIcon:"budget-icon-blue-01.svg"

      },
      {
        to: "detail/Bank",
        text: t("Bank"),
        icon: "bank-icon-01.svg",
        activeIcon:"bank-icon-blue-01.svg"

      },
      {
        to: "/detail/Receivable",
        text: t("Debitorborc"),
        icon: "bag-icon-01.svg",
        activeIcon:"bag-icon-blue-01.svg"
      },
      {
        to: "/detail/Payable",
        text: t("Kreditorborc"),
        icon: "bag-01.svg",
        activeIcon:"bag-blue-01.svg"

      },
      {
        to: "/detail/Expenditure",
        text: t("Pulsilinmə"),
        icon: "coin-up-01.svg",
        activeIcon:"coin-down-blue-01.svg"

      },
      {
        to: "/detail/Income",
        icon: "coin-down-01.svg",
        text: t("Pulmədaxil"),
        activeIcon:"coin-up-blue-01.svg"

      },
      {
        to: "/detail/Inventory",
        icon: "dollar-icon-01.svg",
        text: t("Anbarqalığı"),
        activeIcon:"dollar-icon-blue-01.svg"
      },
      {
        to: "/detail/Production",
        text: t("İstehsal"),
        icon: "money bag-01.svg",
        activeIcon:"money bag-blue-01.svg"
      },
      {
        to: "/detail/Purchase",
        text: t("Alışlar/Geriqaytarmalar"),
        icon: "question-icon-01.svg",
        activeIcon:"question-icon-blue-01.svg"
      },
      {
        to: "/detail/Sale",
        text: t("Satışlar/Geriqaytarmalar"),
        icon: "settings-icon-01.svg",
        activeIcon:"settings-icon-blue-01.svg"

      },
      {
        to: "/detail/Tax",
        text: t("Vergilər"),
        icon: "tax-01.svg",
        activeIcon:"tax-blue-01.svg"

      },
      {
        to: "/detail/Expense",
        text: t("Xərclər"),
        icon: "money-01.svg",
        activeIcon:"money-blue.svg"
      },
    ],
    status_3: [
      {
        to: "/",
        text: t("Home"),
        icon: "fa-solid fa-layer-group",
      },
      {
        to: "/users",
        text: t("İstifadəçilər"),
        icon: "fa-solid fa-layer-group",
      },
    ],
    status_5: [
      {
        to: "/",
        text: t("Home"),
        icon: "home-icon-01.svg",
      },
      ...routes.map(item => ({
        to: item.to,
        text: t(item.text),
        icon: item.icon,
      })),
    ],
  };
  useEffect(() => {
    setMinimize(sidebar);
  }, [sidebar]);
  const mobclose = () => {
    if (window.innerWidth <= 1024) {
      setMinimize(!minimize);
      localStorage.setItem("minimized", !minimize);
      dispatch(changeSidebar({ sidebar: !minimize }));
    }
  };
  function handleChangeLink(par) {
    setActiveLogo(par)
    console.log(par)
  }
  const [minimize, setMinimize] = useState(sidebar);
  return (
    <>
    <div className={minimize?"minimizedContainer":"navContainer"}>
      <nav className={minimize ? "minimized" : ""}>
        <div className="logo">
          <i
            className="fa fa-thumb-tack"
            onClick={() => {
              setMinimize(!minimize);
              localStorage.setItem("minimized", !minimize);
              dispatch(changeSidebar({ sidebar: !minimize }));
            }}
          ></i>
          <h1>Reporto</h1>
          {/* <span>Lorem, ipsum dolor.</span> */}
        </div>
        <ul>
          {links["status_" + statusId].map(l => {
            // console.log(activeLogo)
            // console.log(l.to)
            return (
              
              <li key={l.to}>
                <NavLink to={l.to} onClick={e => {mobclose() ;handleChangeLink(l.to)}}>
                  <img src={"/icons/"+(activeLogo==l.to?l.activeIcon:l.icon)} />
                  <span>{l.text}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
    </>
  );
}

export default Nav;
