// src/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("authState");
    if (serializedState === null) {
      return {
        token: null,
        username: null,
        userFullName: null,
        loading: false,
        error: null,
        isAuthenticated: false,
        companies: [],
        menu: [],
        selectedCompany: null,
        backUpType: 1,
        refreshToken: null,
        Currency: {
          icon: "fa-solid fa-manat-sign",
          value: 1.0,
          id: 1,
          text: "",
        },
      };
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return {
      token: null,
      username: null,
      userFullName: null,
      loading: false,
      error: null,
      isAuthenticated: false,
      companies: [],
      menu: [],
      selectedCompany: null,
      backUpType: 1,
      Currency: {
        icon: "fa-solid fa-manat-sign",
        value: 1.0,
        id: 1,
        text: "",
      },
      refreshToken: null,
    };
  }
};

const saveToLocalStorage = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("authState", serializedState);
  } catch (error) {
    // Handle potential errors here
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: loadFromLocalStorage(),
  reducers: {
    loginStart: state => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      const {
        token,
        refreshtoken,
        username,
        userFullName,
        companies,
        selectedCompany,
      } = action.payload;
      state.token = token;
      state.username = username;
      state.userFullName = userFullName;
      state.companies = companies;
      state.selectedCompany = selectedCompany;
      state.loading = false;
      state.error = null;
      state.menu = [];
      state.isAuthenticated = true;
      state.backUpType = 1;
      state.currency = {
        icon: "fa-solid fa-manat-sign",
        value: 1.0,
        id: 1,
        text: "",
      };
      state.refreshToken = refreshtoken;
      saveToLocalStorage(state);
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeCompany: (state, action) => {
      const { selectedCompany } = action.payload;
      state.selectedCompany = parseInt(selectedCompany);
      saveToLocalStorage(state);
    },
    resetTokens: (state, action) => {
      const { accestoken, refreshToken } = action.payload;
      state.token = accestoken;
      state.refreshToken = refreshToken;
      saveToLocalStorage(state);
    },
    changeMenu: (state, action) => {
      const { menu } = action.payload;
      state.menu = menu;
    },
    changeCurrency: (state, action) => {
      const { currency } = action.payload;
      state.currency = currency;
      saveToLocalStorage(state);
    },
    changeCompanies: (state, action) => {
      const { companies } = action.payload;
      state.companies = companies;
      saveToLocalStorage(state);
    },
    changeBakcUpType: (state, action) => {
      const { backUpType } = action.payload;
      state.backUpType = backUpType;
      saveToLocalStorage(state);
    },
    logout: state => {
      state.token = null;
      state.refreshToken = null;
      state.username = null;
      state.userFullName = null;
      state.loading = false;
      state.error = null;
      state.companies = [];
      state.selectedCompany = null;
      state.isAuthenticated = false;
      state.currency = {
        icon: "fa-solid fa-manat-sign",
        value: 1.0,
        id: 1,
        text: "",
      };
      saveToLocalStorage(state);
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  changeCompany,
  changeCompanies,
  changeBakcUpType,
  changeMenu,
  resetTokens,
  changeCurrency,
} = authSlice.actions;
export default authSlice.reducer;
