import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layoutadmin";
import Home from "./views/Admin/Home";
import NoPage from "./views/NoPage";
import "@fortawesome/fontawesome-free/css/all.min.css";
import CompanyGroups from "./views/Admin/CompanyGroups";
import Companies from "./views/Admin/Companies";
import Employees from "./views/Admin/Employees";
import Rules from "./views/Admin/Rules";
import "./i18n";
function MainAdmin() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/companyGroups" element={<CompanyGroups />} />
          <Route path="/companies/:groupid?" element={<Companies />} />
          <Route path="/employees/:groupid?" element={<Employees />} />
          <Route path="/rules/:filter/:groupid" element={<Rules />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default MainAdmin;
