// urlSlice.js
import { createSlice } from "@reduxjs/toolkit";

const urlSlice = createSlice({
  name: "url",
  initialState: {
    // url: "https://localhost:5062/api/",
    url: "https://reportoapi.kursline.az/api/",
    status: null,
    sidebar:
      window.innerWidth < 526
        ? true
        : localStorage.getItem("minimized") === "true",
    menu: [
      {
        to: "/detail/Cash",
        rolekey: "Cash",
        text: "Kassa",
        icon: "bag-01.svg",
      },
      {
        to: "/detail/Bank",
        rolekey: "Bank",
        text: "Bank",
        icon: "bag-icon-01.svg",
      },
      {
        to: "/detail/Receivable",
        rolekey: "Receivable",
        text: "Debitorborc",
        icon: "bank-icon-01.svg",
      },
      {
        to: "/detail/Payable",
        rolekey: "Payable",
        text: "Kreditorborc",
        icon: "budget-icon-01.svg",
      },
      {
        to: "/detail/Expenditure",
        rolekey: "Expenditure",
        text: "Pulsilinmə",
        icon: "coin-down-01.svg",
      },
      {
        to: "detail/Income",
        rolekey: "Income",
        icon: "coin-up-01.svg",
        text: "Pulmədaxil",
      },
      {
        to: "detail/Inventory",
        rolekey: "Inventory",
        icon: "dollar-icon-01.svg",
        text: "Anbarqalığı",
      },
      {
        to: "detail/Production",
        rolekey: "Production",
        text: "İstehsal",
        icon: "money bag-01.svg",
      },
      {
        to: "detail/Purchase",
        rolekey: "Purchase",
        text: "Alışlar/Geriqaytarmalar",
        icon: "question-icon-01.svg",
      },
      {
        to: "detail/Sale",
        rolekey: "Sale",
        text: "Satışlar/Geriqaytarmalar",
        icon: "settings-icon-01.svg",
      },
      {
        to: "detail/Tax",
        rolekey: "Tax",
        text: "Vergilər",
        icon: "summary data-01.svg",
      },
      {
        to: "detail/Expense",
        rolekey: "Expense",
        text: "Xərclər",
        icon: "summary data-01.svg",
      },
    ],
    notification: {
      type: false,
      message: null,
    },
  },
  reducers: {
    changeStatus: (state, action) => {
      const { status } = action.payload;
      state.status = parseInt(status);
    },
    changeSidebar: (state, action) => {
      const { sidebar } = action.payload;
      state.sidebar = sidebar;
    },
    changeNot: (state, action) => {
      const { type, message } = action.payload;
      state.notification.type = type;
      state.notification.message = message;
    },
  },
});

export const { changeStatus, changeSidebar, changeNot } = urlSlice.actions;
export default urlSlice.reducer;
