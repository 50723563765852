import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PieChart from "../../components/Charts/PieChart";
import ColumnChart from "../../components/Charts/ColumnChart";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import { changeNot } from "../../store/urlSlice";
import MainError from "../../components/Notifications/MainError";
import usePrivate from "../../store/useAxiosPrivate2";
function Cash() {
  const currency = useSelector(state => state.auth.currency);
  const { sendRequest } = usePrivate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notification = useSelector(state => state.url.notification);
  const selectedCompany = useSelector(state => state.auth.selectedCompany);
  const BackUpType = useSelector(state => state.auth.backUpType);
  const [db, setdb] = useState(null);
  const [type, setType] = useState("chart");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest(
          "GET",
          `GetData/Cash/${selectedCompany}/${BackUpType}`,
          null
        );
        console.log(res.result);

        dispatch(changeNot({ type: res.isSuccess, message: res.message }));
        setdb(res);
      } catch (err) {
        dispatch(changeNot({ type: err.isSuccess, message: err.message }));
        console.log(err);
      }
    };
    fetchData();
  }, [BackUpType, selectedCompany]);

  return db !== null ? (
    db.isSuccess ? (
      db.result.line.length > 0 ? (
        <div className="cash-detail">
          <div className="cash-head">
            <h3>
              {t("Ümumivəsait")} :{" "}
              {db.isSuccess
                ? db.result.line[db.result.line.length - 1][
                    `y${currency.text}`
                  ].toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0}
              <i className={currency.icon}></i>
            </h3>
            <ul className="show-type">
              <li
                className={type !== "table" ? "active" : "hide"}
                onClick={() => setType("table")}
              >
                <span>{t("Cədvəlgörünüşü")}</span>
                <i className="fa-solid fa-bars"></i>
              </li>
              <li
                className={type !== "chart" ? "active" : "hide"}
                onClick={() => setType("chart")}
              >
                <span>{t("Qrafikgörünüşü")}</span>
                <i className="fa-solid fa-chart-line"></i>
              </li>
            </ul>
          </div>
          <div className="pie">
            {type === "chart"
              ? db.result.pie && (
                  <PieChart
                    s={
                      db.result.pie.map(d => d[`cashamount${currency.text}`]) ||
                      0
                    }
                    l={db.result.pie.map(d => d.cashname)}
                  />
                )
              : db.result.pie && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("Kassaadı")}</th>
                        <th>{t("Məbləğ")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {db.result.pie.map((d, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{d.cashname}</td>
                            <td className="textal">
                              <span>
                                {d[
                                  `cashamount${currency.text}`
                                ]?.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? "0.00"}
                                <i className={currency.icon}></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
          </div>
          <div className="area">
            {type === "chart"
              ? db.result.line && (
                  <ColumnChart
                    data={db.result.line}
                    height="100%"
                    toolbar={true}
                    labelx={true}
                    labely={true}
                  />
                )
              : db.result.line && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("Tarix")}</th>
                        <th>{t("Məbləğ")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...db.result.line].reverse().map((d, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{d.x}</td>
                            <td className="textal">
                              <span>
                                {d[`y${currency.text}`]?.toLocaleString(
                                  "tr-TR",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                ) ?? "0.00"}
                                <i className={currency.icon}></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
          </div>
        </div>
      ) : (
        <div style={{ padding: "45px 13px" }}>
          <h2 className="section-head" style={{ marginLeft: "20px" }}>
            {t("Məlumatyoxdur")}
          </h2>
        </div>
      )
    ) : (
      <div style={{ padding: "45px 13px" }}>
        <h2 className="section-head" style={{ marginLeft: "20px" }}>
          {t("Məlumatyoxdur")}
        </h2>
        {notification ? <MainError /> : null}
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#0d2e4e"}
        color={"#0d2e4e"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  );
}

export default Cash;
