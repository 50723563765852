import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Error from "../../components/Notifications/Error";
import Loader from "react-js-loader";
import Store from "../../store/Store";
import axiosprivate from "../../store/useAxiosPrivate2";
const url = Store.getState().url.url;

function Rules() {
  const { sendRequest } = axiosprivate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [rules, setRules] = useState([]);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const token = useSelector(state => state.auth.token);
  const { groupid, filter } = useParams();
  const [notification, setNotification] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest(
          "GET",
          `Companies/GetRules?${filter}=${groupid}`,
          null
        );
        console.log(res);
        setData(res.userrules);
        setUsers(res.users);
        setCompanies(res.companies);
        setRules(res.rules);
        // setData(res.data);
        // setGroups(res.groups);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);
  function distinct(arr) {
    const kvArray = arr.map(entry => {
      const key = ["userrulecompanyid", "userruleuserid"]
        .map(k => entry[k])
        .join("|");
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
  const handleChange = e => {
    let { name, value } = e.target;
    setData(data =>
      data.map(d => (d.type === "new" ? { ...d, [name]: parseInt(value) } : d))
    );
  };

  // const rightClick = (e, id) => {
  //     e.preventDefault()
  //     document.querySelector('.context-menu').style = "block"
  //     document.querySelector('.context-menu').style.left = e.pageX + "px"
  //     document.querySelector('.context-menu').style.top = e.pageY + "px"
  //     setCurRowId(id)
  // }
  const addRow = () => {
    setData(
      data.concat({
        userruleid: null,
        userrulecompanyid: null,
        userruleruleid: null,
        userruleuserid: null,
        type: "new",
      })
    );
  };
  const checkRule = (ruleId, userId, companyid) => {
    return data.findIndex(
      x =>
        x.userruleruleid === ruleId &&
        x.userruleuserid === userId &&
        x.userrulecompanyid === companyid
    );
  };
  const SendData = async (value, method, url) => {
    try {
      // Example of using sendRequest for GET request
      const res = await sendRequest(method, url);
      console.log(res);
      if (res.data.exits) {
        setData(data.filter(x => x.userruleid !== res.data.userruleid));
      } else {
        setData([
          ...data.filter(x => x.type !== "new"),
          {
            userruleid: res.data.userruleid,
            userrulecompanyid: value.companyid,
            userruleruleid: value.ruleid,
            userruleuserid: value.userid,
          },
        ]);
      }
      setNotification({
        type: 1,
        text: t("Success"),
      });
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    } catch (error) {
      console.error(error);
      setNotification({
        type: 0,
        text: error.response.data.detail,
      });
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };
  const ChangeRule = (ruleid, userid, companyid) => {
    console.log("ruleid", ruleid, "userid", userid, "compid", companyid);
    const data = {
      ruleid: ruleid,
      userid: userid,
      companyid: companyid,
    };
    SendData(
      data,
      "POST",
      `Companies/ChangeRule/${userid}/${ruleid}/${companyid}`
    );
  };
  return data !== null ? (
    <>
      <div className="container">
        <h2 className="section-head">{t("Səlahiyyətlər")}</h2>
        <table className="table-box vertical-header">
          <thead>
            <tr>
              <th></th>
              <th></th>
              {rules.map(r => {
                return (
                  <th key={"rule" + r.ruleid}>
                    {t(r.rulename.replace(/ /g, ""))}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {distinct(data).map(u => {
              return (
                <tr key={"row" + u.userruleid}>
                  <td>
                    {u.type !== "new" ? (
                      users.find(x => x.userid === u.userruleuserid).username
                    ) : (
                      <select
                        name="userruleuserid"
                        onChange={e => handleChange(e)}
                      >
                        <option value="">{t("seçin")}</option>
                        {users.map(x => {
                          return (
                            <option value={x.userid} key={"optusr" + x.userid}>
                              {x.username}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </td>
                  <td>
                    {u.type !== "new" ? (
                      companies.find(x => x.companyid === u.userrulecompanyid)
                        .companyname
                    ) : (
                      <select
                        name="userrulecompanyid"
                        onChange={e => handleChange(e)}
                      >
                        <option value="">{t("seçin")}</option>
                        {companies.map(x => {
                          return (
                            <option
                              value={x.companyid}
                              key={"optcomp" + x.companyid}
                            >
                              {x.companyname}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </td>
                  {rules.map(r => {
                    return (
                      <td
                        key={
                          "chk" +
                          r.ruleid +
                          "-" +
                          u.userrulecompanyid +
                          "-" +
                          u.userruleuserid
                        }
                      >
                        <input
                          type="checkbox"
                          onChange={() =>
                            ChangeRule(
                              r.ruleid,
                              u.userruleuserid,
                              u.userrulecompanyid
                            )
                          }
                          checked={
                            checkRule(
                              r.ruleid,
                              u.userruleuserid,
                              u.userrulecompanyid
                            ) === -1
                              ? ""
                              : "checked"
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <button className="addrow" onClick={addRow}>
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
      {notification != null && (
        <Error text={notification.text} type={notification.type} />
      )}
    </>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader
        type="box-rectangular"
        bgColor={"#2db686"}
        color={"#2db686"}
        title={"REPORTO"}
        size={80}
      />
    </div>
  );
}

export default Rules;
